<template>
  <v-container style="height: 100%" fluid>
    <v-row style="height: 100%" justify="center" align-content="center">
      <v-col cols="5">
        <v-card elevation="5">
          <v-card-title class="justify-center">
            Googleでログイン
          </v-card-title>
          <v-card-text />
          <v-card-actions>
            <v-btn block color="success" @click="login">ログイン</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getAuth, onAuthStateChanged, signInWithPopup, GoogleAuthProvider } from 'firebase/auth'

export default {
  name: "Login",
  methods: {
    login: function () {
      const provider = new GoogleAuthProvider();
      const auth = getAuth();
      signInWithPopup(auth, provider);
    },
  },
  created() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if(user) {
        this.$router.push('/');
      }
    });
  }
};
</script>
