<template>
  <v-container fluid>
    <h1 class="mt-5 ms-5">商談一覧</h1>
    <v-row class="mt-5">
      <v-col cols="12">
        <v-card elevation="5">
          <v-card-title>
            <v-text-field class="mx-3" @change="doSearch" v-model="search.text" label="検索" clearable />
            <v-autocomplete class="mx-3" @change="doSearch" v-model="search.customer" label="顧客" clearable :items="allAccount" item-text="name" item-value="id" />
            <v-select class="mx-3" @change="doSearch" v-model="search.nextPhase" label="次フェーズ" clearable :items="['手配','納品','請求','入金・支払','完了済']" />
          </v-card-title>
          <v-card-text>
            <v-data-table
              hide-default-footer
              @click:row="(item) => $router.push('/opp/' + item.id)"
              :items-per-page="-1"
              :headers="oppTableHeaders"
              :items="allOpp"
              :item-class="oppTableClass"
              :search="$route.query.text"
            >
              <!-- フェーズ -->
              <template v-slot:[`item.phase`]="{ item }">
                {{ phaseName(item.phase) }}
              </template>
              <!-- 納品予定日 -->
              <template v-slot:[`item.deliveryExpectedDate`]="{ item }">
                {{ (item.deliveryExpectedDate != undefined && item.deliveryExpectedDate != "") ? item.deliveryExpectedDate.split("-")[1]+"/"+item.deliveryExpectedDate.split("-")[2] : "" }}
              </template>
              <!-- 売上予定月 -->
              <template v-slot:[`item.salesExpectedMonth`]="{ item }">
                {{ (item.salesExpectedMonth != undefined && item.salesExpectedMonth != "") ? item.salesExpectedMonth.split("-")[0]+"年"+item.salesExpectedMonth.split("-")[1]+"月" : "" }}
              </template>
              <!-- 販売金額 -->
              <template v-slot:[`item.salesPrice`]="{ item }">
                ¥{{ item.product.reduce((sum, e) => sum+e.unitPrice*e.quantity, 0).toLocaleString() }}
              </template>
              <!-- 仕入金額 -->
              <template v-slot:[`item.supplyPrice`]="{ item }">
                ¥{{ item.product.reduce((sum, e) => sum+e.supplyPrice, 0).toLocaleString() }}
              </template>
              <!-- 利益 -->
              <template v-slot:[`item.profit`]="{ item }">
                <v-chip dark :color="(profit(item)>0) ? 'success' : (profit(item)!==0) ? 'error': '' ">
                  ¥{{ profit(item).toLocaleString() }}
                </v-chip>
              </template>
              <!-- 利益率 -->
              <template v-slot:[`item.profitRate`]="{ item }">
                <v-chip dark :color="(profitRate(item)>0) ? 'success' : (profitRate(item)!==0) ? 'error': '' ">
                  {{ profitRate(item) }}%
                </v-chip>
              </template>
              <!-- 販売先 -->
              <template v-slot:[`item.customerId`]="{ item }">
                {{ (allAccount.length > 0) ? allAccount.find(account => account.id ==  item.customerId).name : '' }}
              </template>
              <!-- 操作 -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click.stop="deleteOpp(item)" v-text="'mdi-delete'" />
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="editDialog" max-width="800">
      <v-card elevation="5">
        <v-card-title>商談作成</v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="4">
              <v-text-field outlined v-model="editOpp.id" label="ID" />
            </v-col>
            <v-col cols="8">
              <v-text-field outlined v-model="customerName" readonly @click="selectCustomerDialog=true" label="顧客" />
            </v-col>
            <v-col cols="9" />
            <v-col cols="3">
              <v-btn color="primary" @click="selectNameDialog=true">商品から選択</v-btn>
            </v-col>
            <v-col cols="12">
              <v-text-field outlined v-model="editOpp.name" label="商談名" />
            </v-col>
            <v-col class="mt-8" cols="8">
              <v-btn @click="createOpp" class="success" block>作成</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-btn
      x-large
      fixed
      color="blue"
      dark
      bottom
      right
      fab
      @click="openDialog"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <v-dialog v-model="selectCustomerDialog" max-width="1000">
      <v-card>
        <v-card-title>
          <v-text-field append-icon="mdi-magnify" label="検索" v-model="customerSearch" />
        </v-card-title>
        <v-card-text>
          <v-data-table
            height="400"
            @click:row="selectCustomer"
            :search="customerSearch"
            :items="allAccount"
            :headers="customerTableHeaders"
            :items-per-page="-1"
            hide-default-footer
          ></v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="selectSearchCustomerDialog" max-width="1000">
      <v-card>
        <v-card-title>
          <v-text-field append-icon="mdi-magnify" label="検索" v-model="customerSearch" />
        </v-card-title>
        <v-card-text>
          <v-data-table
            height="400"
            @click:row="selectSearchCustomer"
            :search="customerSearch"
            :items="allAccount"
            :headers="customerTableHeaders"
            :items-per-page="-1"
            hide-default-footer
          ></v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="selectNameDialog" max-width="800">
      <v-data-table
        height="400"
        @click:row="selectName"
        :items="product"
        :headers="customerTableHeaders"
        :items-per-page="-1"
        hide-default-footer
      ></v-data-table>
    </v-dialog>

  </v-container>
</template>

<script>
import { getFirestore } from 'firebase/firestore'

import Account from '../model/account';
import Opp from '../model/opp';

export default {
  name: 'OppList',
  data: () => ({
    // ダイアログのフラグ
    editDialog: false,
    selectNameDialog: false,
    selectCustomerDialog: false,
    selectSearchCustomerDialog: false,
    // テーブル検索
    search: {
      text: '',
      customer: '',
      nextPhase: '',
    },
    // 顧客検索
    customerSearch: "",
    customerName: "",
    editOpp: {},
    allOpp: [],
    allAccount: [],
    product: [],
  }),
  methods: {
    getAllOpp: async function() {
      this.allOpp = await Opp.getAll(getFirestore());
    },
    getAllAccount: async function() {
      this.allAccount = await Account.getAll(getFirestore());
    },
    openDialog: async function() {
      this.editDialog = true;
      this.customerName = "";
      this.product = [];
      this.editOpp = Opp.getInitJson();
      const nowNum = String(new Date().getFullYear()).slice(-2) + String(new Date().getMonth()+1).padStart(2, '0');
      const latestId = await Opp.getLatestId(getFirestore());
      if(nowNum === latestId.split('-')[0]) this.editOpp['id'] = nowNum+'-'+String(Number(latestId.split('-')[1])+1).padStart(3, '0');
      else this.editOpp['id'] = nowNum+'-001';
    },
    createOpp: async function() {
      if(confirm("作成しますか?")) {
        const error = await Opp.create(getFirestore(), this.editOpp);
        if(error) {
          alert("IDが重複しています");
        } else {
          this.getAllOpp();
          this.editDialog = false;
        }
      }
    },
    deleteOpp: async function(item) {
      if(confirm(item.name+'を削除してよろしいですか?')) {
        await item.delete(getFirestore());
        this.getAllOpp();
      }
    },
    oppTableClass: function(item) {
      if(item.deliveryDate == "" && (item.deliveryExpectedDate == "" || item.deliveryExpectedDate == undefined || new Date() > new Date(item.deliveryExpectedDate)))return "red lighten-4";
      else if(item.deliveryDate == "") return "yellow lighten-4";
      else if(item.phase.arrange && item.phase.billing && item.phase.delivery && item.phase.deposit && item.phase.payment) return "green lighten-4";
      else return "";
    },
    doSearch: function() {
      this.$router.push({
        path: '/opp',
        query: {
          text: (this.search.text && this.search.text != '') ? this.search.text : undefined,
          customer: (this.search.customer) ? this.search.customer : undefined,
          nextPhase: (this.search.nextPhase) ? this.search.nextPhase : undefined
        }
      });
    },
    selectCustomer: async function(item) {
      this.customerName = item.name;
      this.editOpp.customerId = item.id;
      this.selectCustomerDialog = false;
      await item.getProduct(getFirestore());
      this.product = item.product;
    },
    selectSearchCustomer: function(item) {
      this.search.customerName = item.name;
      this.search.customer = item.id;
      this.selectSearchCustomerDialog = false;
      this.doSearch();
    },
    clearCustomerSearch: function() {
      this.search.customer = "";
      this.search.customerName = "";
      this.doSearch();
    },
    selectName: function(item) {
      this.editOpp.name = item.name;
      this.selectNameDialog = false;
    },
    phaseName(item) {
      if(item.payment && item.deposit) return "完了済";
      else if(item.payment || item.deposit || item.billing) return "入金・支払";
      else if(item.delivery) return "請求";
      else if(item.arrange) return "納品";
      else return "手配";
    },
    getSearchQuery() {
      this.search = {
        text: typeof(this.$route.query.text) == 'string' ? this.$route.query.text : '',
        customer: typeof(this.$route.query.customer) == 'string' ? Number(this.$route.query.customer) : null,
        nextPhase: typeof(this.$route.query.nextPhase) == 'string' ? this.$route.query.nextPhase : '',
      }
    }
  },
  computed: {
    // 商談一覧テーブルヘッダー
    oppTableHeaders() {
      return [
        { text: 'ID', value: 'id' },
        { text: '販売先', value: 'customerId', filter: customerId => {if(this.$route.query.customer == undefined) return true; else if(customerId==this.$route.query.customer) return true; else return false;} },
        { text: '名前', value: 'name' },
        { text: '次フェーズ', value: 'phase', filter: (v,s,opp) => (!this.search.nextPhase || this.phaseName(opp.phase) == this.search.nextPhase) },
        { text: '納品予定日', value: 'deliveryExpectedDate' },
        { text: '売上予定月', value: 'salesExpectedMonth' },
        { text: '販売金額', value: 'salesPrice' },
        { text: '仕入金額', value: 'supplyPrice' },
        { text: '利益', value: 'profit' },
        { text: '利益率', value: 'profitRate' },
        { text: '操作', value: 'actions' },
      ]
    },
    // 顧客一覧テーブルヘッダー
    customerTableHeaders() {
      return [
        { text: 'ID', value: 'id' },
        { text: '顧客名', value: 'name' },
      ]
    },
    // 利益計算
    profit() {
      return (item) => {
        return item.product.reduce((sum, e) => sum+(e.unitPrice*e.quantity-e.supplyPrice), 0);
      }
    },
    // 利益率計算
    profitRate() {
      return (item) => {
        const ret = item.product.reduce((sum, e) => sum+(e.unitPrice*e.quantity-e.supplyPrice)*1.1, 0)/item.product.reduce((sum, e) => sum+(e.unitPrice*e.quantity)*1.1, 0);
        if(isNaN(ret)) return 0;
        else return Math.floor(ret*10000)/100;
      }
    },
  },
  created() {
    this.getAllOpp();
    this.getAllAccount();
    this.getSearchQuery();
  },
  watch: {
    $route: function() {
      this.getSearchQuery();
    }
  }
};
</script>
