import * as firestore from "firebase/firestore"
import { httpsCallable } from 'firebase/functions';
import Opp from "./opp";
import Account from "./account";

class Invoice {
  constructor(docId, data) {
    this.docId = docId;
    this.id = data.id;
    this.opp = data.opp;
    this.sheet = data.sheet;
    this.billingCheck = data.billingCheck;
    this.depositCheck = data.depositCheck;
  }

  static async getAll(db) {
    const ret = new Array();
    const ref = firestore.collection(db, "invoice");
    const q = firestore.query(ref, firestore.orderBy("id", "desc"));
    const snapshot = await firestore.getDocs(q);
    snapshot.forEach((e) => {
      ret.push(new this(e.id, e.data()));
    });
    return ret;
  }

  static async create(db, func, oppId, customerId, billingDate) {
    const colRef = firestore.collection(db, "invoice");
    const id = customerId + "-" + billingDate.split("-")[0].slice(-2) + billingDate.split("-")[1] + billingDate.split("-")[2];
    const q = firestore.query(colRef, firestore.where("id", "==", id));
    const snapshot = await firestore.getDocs(q);
    const createInvoice = httpsCallable(func, "createInvoice");
    const customer = await Account.get(db, customerId);
    if(snapshot.size > 0) {
      snapshot.forEach((e) => {
        const docRef = firestore.doc(db, "invoice", e.id);
        firestore.updateDoc(docRef, {
          "opp": firestore.arrayUnion(oppId),
          "billingCheck": "",
          "depositCheck": "",
        });  
      });
    } else {
      const sheet = (await createInvoice({
        name: customer.name + "様_" + billingDate.split("-")[0].slice(-2) + "年" + billingDate.split("-")[1] + "月分"
      })).data;
      firestore.addDoc(colRef, {
        "id": id,
        "opp": [oppId],
        "sheet": sheet,
        "billingCheck": "",
        "depositCheck": "",
      });
    }
  }

  static async remove(db, oppId) {
    const colRef = firestore.collection(db, "invoice");
    const q = firestore.query(colRef, firestore.where("opp", "array-contains", oppId));
    const snapshot = await firestore.getDocs(q);
    if(snapshot.size > 0) {
      let docRef = null;
      let deleteFlg = true;
      snapshot.forEach((e) => {
        docRef = firestore.doc(db, "invoice", e.id);
        if(e.data().opp.length > 1) deleteFlg = false;
      });
      if(deleteFlg) {
        await firestore.deleteDoc(docRef);
      } else {
        firestore.updateDoc(docRef, {
          "billingCheck": "",
          "depositCheck": "",
          "opp": firestore.arrayRemove(oppId),
        });  
      }
    }
  }

  async update(db, data) {
    const docRef = firestore.doc(db, "invoice", this.docId);
    firestore.updateDoc(docRef, data);
  }

  async checkBilling(db) {
    const docRef = firestore.doc(db, "invoice", this.docId);
    await firestore.updateDoc(docRef, {
      "billingCheck": this.billingCheck,
    });
    this.opp.forEach((opp) => {
      Opp.get(db, opp).then(e => {
        e.update(db, {
          "phase.billing": (this.billingCheck!="") ? true : false,
        });
      });
    });  
  }

  async checkDeposit(db) {
    const docRef = firestore.doc(db, "invoice", this.docId);
    await firestore.updateDoc(docRef, {
      "depositCheck": this.depositCheck,
    });
    this.opp.forEach((opp) => {
      Opp.get(db, opp).then(e => {
        e.update(db, {
          "phase.deposit": (this.depositCheck!="") ? true : false,
        });
      });
    });
  }

}

export default Invoice;