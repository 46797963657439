import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import Dashboard from '../views/Dashboard.vue';
import OppList from '../views/OppList.vue';
import OppPage from '../views/OppPage.vue';
import AccountList from '../views/AccountList.vue';
import AccountPage from '../views/AccountPage.vue';
import ProductList from '../views/ProductList.vue';
import ProductPage from '../views/ProductPage.vue';
import SupplyList from '../views/SupplyList.vue';
import InvoiceList from '../views/InvoiceList.vue';

import Labs from '../views/Labs.vue'

import NotFound from '../views/NotFound.vue';

import { getAuth, onAuthStateChanged } from 'firebase/auth'

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { requiresAuth: false }
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresAuth: true }
  },
  {
    path: '/opp',
    name: 'OppList',
    component: OppList,
    meta: { requiresAuth: true }
  },
  {
    path: '/opp/:id',
    name: 'OppPage',
    component: OppPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/account',
    name: 'AccountList',
    component: AccountList,
    meta: { requiresAuth: true }
  },
  {
    path: '/account/:id',
    name: 'AccountPage',
    component: AccountPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/product',
    name: 'ProductList',
    component: ProductList,
    meta: { requiresAuth: true }
  },
  {
    path: '/product/:id',
    name: 'ProductPage',
    component: ProductPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/supply',
    name: 'SupplyList',
    component: SupplyList,
    meta: { requiresAuth: true }
  },
  {
    path: '/invoice',
    name: 'InvoiceList',
    component: InvoiceList,
    meta: { requiresAuth: true }
  },
  {
    path: '/labs',
    name: 'Labs',
    component: Labs,
    meta: { requiresAuth: true }
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if(requiresAuth) {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
    if (user) {
        next();
      } else {
        next({path: '/login'});
      }
    });
  } else {
    next();
  }
})
export default router
