import * as firestore from "firebase/firestore"
import {httpsCallable} from "firebase/functions"
import Account from './account'

class Product {
  constructor(docId, data) {
    this.docId = docId;
    this.id = data.id;
    this.customerId = data.customerId;
    this.customer = {};
    this.folderId = data.folderId;
    this.name = data.name;
    this.unit = data.unit;
    this.price = data.price;
    this.specification = data.specification;
  }
  
  static getInitJson() {
    return {
      id: "",
      customerId: "",
      name: "",
      folderId: "",
      unit: "",
      price: [],
      specification: "",
    }
  }

  static async get(db, customerId, id) {
    let ret = null;
    const ref = firestore.collection(db, "product");
    const q = firestore.query(ref, firestore.where("customerId", "==", customerId), firestore.where("id", "==", id), firestore.limit(1));
    const snapshot = await firestore.getDocs(q);
    snapshot.forEach((e) => {
      ret = new this(e.id, e.data());
    });
    return ret;
  }

  static async getAll(db) {
    const ret = new Array();
    const ref = firestore.collection(db, "product");
    const q = firestore.query(ref, firestore.orderBy("customerId"), firestore.orderBy("id"));
    const snapshot = await firestore.getDocs(q);
    snapshot.forEach((e) => {
      ret.push(new this(e.id, e.data()));
    });
    return ret;
  }

  static async getAccount(db) {
    this.customer = Account.get(db, this.customerId);
  }

  static getInitPriceJson() {
    return {
      "unitPrice": 0,
      "tax": 10,
      "quantity": 0,
      "supplierId": 0,
      "supplyPrice": 0
    }
  }

  async addPrice(db, data) {
    const ref = firestore.doc(db, "product", this.docId);
    await firestore.updateDoc(ref, {
      "price": firestore.arrayUnion(data),
    });
  }
  
  async deletePrice(db, data) {
    const ref = firestore.doc(db, "product", this.docId);
    await firestore.updateDoc(ref, {
      "price": firestore.arrayRemove(data),
    });
  }

  toJson() {
    const ret = JSON.parse(JSON.stringify(this));
    delete ret["docId"];
    delete ret["customer"];
    return ret;
  }

  async createFolder(db, func) {
    let customerName = "";
    const colRef = firestore.collection(db, "account");
    const q = firestore.query(colRef, firestore.where("id", "==", this.customerId), firestore.limit(1));
    const snapshot = await firestore.getDocs(q);
    snapshot.forEach((e) => {
      customerName = e.data().name;
    });
    if(this.folderId == "") {
      const createFolder = httpsCallable(func, "createFolder");
      const res = await createFolder({
        name: this.customerId + "-" + this.id + "_" + customerName + "様_" + this.name,
        parents: "1T5BNKTLck64SAzrJ5iFtXBC04A3hvA-H",
      });
      const docRef = firestore.doc(db, "product", this.docId);
      await firestore.updateDoc(docRef, {
        "folderId": res.data.id,
      });
    }
  }

  async updateFolder(db, func, data) {
    let customerName = "";
    const colRef = firestore.collection(db, "account");
    const q = firestore.query(colRef, firestore.where("id", "==", this.customerId), firestore.limit(1));
    const snapshot = await firestore.getDocs(q);
    snapshot.forEach((e) => {
      customerName = e.data().name;
    });
    const updateFolder = httpsCallable(func, "updateFolder");
    await updateFolder({
      id: this.folderId,
      name: this.customerId + "-" + this.id + "_" + customerName + "様_" + data.name,
    })
  }

  async edit(db, data) {
    const ref = firestore.doc(db, "product", this.docId);
    await firestore.setDoc(ref, data);
  }

  async delete(db) {
    const ref = firestore.doc(db, "product", this.docId);
    await firestore.deleteDoc(ref);
  }

}

export default Product;