<template>
  <v-container fluid>
    <h1 class="mt-5 ms-5">商品一覧</h1>
    <v-row class="mt-5">
      <v-col cols="12">
        <v-card elevation="5">
          <v-card-title>
            <v-text-field @change="doSearch" clearable label="テキストを入力" v-model="search.text" />
            <v-autocomplete class="mx-3" @change="doSearch" v-model="search.customer" label="顧客" clearable :items="allAccount" item-text="name" item-value="id" />
          </v-card-title>
          <v-card-text>
            <v-data-table
              hide-default-footer
              @click:row="(item) => $router.push('/product/'+item.customerId+'-'+item.id)"
              :items-per-page="-1"
              :headers="productTableHeaders"
              :items="allProduct"
              :search="$route.query.text"
              item-key="customerId+id"
            >
              <!-- ID -->
              <template v-slot:[`item.id`]="{ item }">
                {{ item.customerId }}-{{ item.id }}
              </template>
              <!-- 取引先 -->
              <template v-slot:[`item.customerId`]="{ item }">
                {{ (allAccount.find(account => account.id ==  item.customerId)) ? allAccount.find(account => account.id ==  item.customerId).name : '' }}
              </template>
              <!-- 操作 -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click.stop="deleteProduct(item)" v-text="'mdi-delete'" />
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="selectSearchCustomerDialog" max-width="1000">
      <v-card>
        <v-card-title>
          <v-text-field append-icon="mdi-magnify" label="検索" v-model="customerSearch" />
        </v-card-title>
        <v-card-text>
          <v-data-table
            height="400"
            @click:row="selectSearchCustomer"
            :search="customerSearch"
            :items="allAccount"
            :headers="customerTableHeaders"
            :items-per-page="-1"
            hide-default-footer
          ></v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import { getFirestore } from 'firebase/firestore'

import Account from '../model/account';
import Product from '../model/product';

export default {
  name: 'ProductList',
  data: () => ({
    // ダイアログのフラグ
    editDialog: false,
    selectSearchCustomerDialog: false,
    // 新規作成
    editAccount: {},
    // テーブル検索
    search: {
      text: '',
      customer: undefined,
      customerName: undefined,
    },
    customerSearch: "",
    // インスタンス配列
    allProduct: [],
    allAccount: []
  }),
  methods: {
    getAllProduct: async function() {
      this.allProduct = await Product.getAll(getFirestore());
    },
    getAllAccount: async function() {
      this.allAccount = await Account.getAll(getFirestore());
    },
    getSearchQuery: function() {
      this.search = {
        text: typeof(this.$route.query.text) == 'string' ? this.$route.query.text : '',
        customer: typeof(this.$route.query.customer) == 'string' ? Number(this.$route.query.customer) : '',
      };
    },
    deleteProduct: async function(product) {
      if(confirm(product.name+'を削除しますか?')) {
        await product.delete(getFirestore());
        this.getAllProduct();
      }
    },
    doSearch() {
      this.$router.push({
        path: '/product',
        query: {
          text: (this.search.text && this.search.text != '') ? this.search.text : undefined,
          customer: (this.search.customer) ? this.search.customer : undefined,
        }
      });
    },
    selectSearchCustomer: function(item) {
      this.search.customerName = item.name;
      this.search.customer = item.id;
      this.selectSearchCustomerDialog = false;
      this.doSearch();
    },
    clearCustomerSearch: function() {
      this.search.customer = "";
      this.search.customerName = "";
      this.doSearch();
    }
  },
  computed: {
    // 一覧テーブルヘッダー
    productTableHeaders() {
      return [
        { text: 'ID', value: 'id' },
        { text: '取引先', value: 'customerId', filter: customerId => {if(this.$route.query.customer == undefined) return true; else if(customerId==this.search.customer) return true; else return false;} },
        { text: '商品名', value: 'name' },
        { text: '操作', value: 'actions' },
      ]
    },
    customerTableHeaders() {
      return [
        { text: 'ID', value: 'id' },
        { text: '顧客名', value: 'name' },
      ]
    },
  },
  created() {
    this.getAllProduct();
    this.getAllAccount();
    this.getSearchQuery();
  },
  watch: {
    $route: function() {
      this.getSearchQuery();
    }
  }
};
</script>
