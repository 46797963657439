import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'

// Firebase
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyB_S-SRVeco_Hnsx-Pl80OHmxoq5bogV4c",
  authDomain: "ofpri-app-2bf1b.firebaseapp.com",
  projectId: "ofpri-app-2bf1b",
  storageBucket: "ofpri-app-2bf1b.appspot.com",
  messagingSenderId: "570375643794",
  appId: "1:570375643794:web:0ecdf1cc8673a9902cbc4b"
};
initializeApp(firebaseConfig);

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
