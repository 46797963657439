<template>
  <v-container style="height: 100%" fluid>
    <h1 class="mt-5 ms-5">Labs</h1>
    <v-row class="mt-5">
      <v-col>
        <v-card>
          <v-card-title>請求書作成</v-card-title>
          <v-card-actions>
            <v-btn color="primary" @click="createInvoice">実行</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import { getFirestore } from 'firebase/firestore';
// import Opp from '../model/opp';

export default {
  name: "Labs",
  methods: {
    createInvoice: async function() {
      console.log("Start");
      console.log("Complete");
    }
  },
};
</script>
