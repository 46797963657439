<template>
  <v-container style="height: 100%" fluid>

    <v-row v-if="loading" style="height: 100%" justify="center" align-content="center">
      <v-col>
        <v-row justify="center">
          <v-progress-circular color="grey darken-2" size="80" width="6" indeterminate />
        </v-row>
      </v-col>
    </v-row>

    <template v-else>
      <h1 class="mt-5 ms-5">請求書一覧</h1>
      <v-row class="mt-5">
        <v-col cols="9">
          <v-card elevation="5">
            <v-card-text>
              <v-data-table
                hide-default-footer
                :items-per-page="-1"
                :headers="invoiceTableHeaders"
                :items="allInvoice"
                :search="$route.query.text"
              >
                <!-- 年月 -->
                <template v-slot:[`item.date`]="{ item }">
                  {{ item.id.split("-")[1].slice(0,2)+"年"+item.id.split("-")[1].slice(2,4)+"月分" }}
                </template>              
                <!-- 取引先名 -->
                <template v-slot:[`item.customer`]="{ item }">
                  {{ (allAccount.length > 0) ? allAccount.find(account => account.id ==  item.id.split("-")[0]).name : '' }}
                </template>
                <!-- 請求チェック -->
                <template v-slot:[`item.billingCheck`]="{ item }">
                  <v-menu
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="item.billingCheck"
                        prepend-icon="mdi-calendar"
                        clearable
                        @click:clear="clearCheckBilling(item)"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-card>
                      <v-date-picker
                        v-model="item.billingCheck"
                        locale="jp-ja"
                        :day-format="date => new Date(date).getDate()"
                        no-title
                        @change="checkBilling(item)"
                      ></v-date-picker>
                    </v-card>
                  </v-menu>
                </template>
                <!-- 支払チェック -->
                <template v-slot:[`item.depositCheck`]="{ item }">
                  <v-menu
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="item.depositCheck"
                        prepend-icon="mdi-calendar"
                        clearable
                        @click:clear="clearCheckDeposit(item)"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-card>
                      <v-date-picker
                        v-model="item.depositCheck"
                        locale="jp-ja"
                        :day-format="date => new Date(date).getDate()"
                        no-title
                        @change="checkDeposit(item)"
                      ></v-date-picker>
                    </v-card>
                  </v-menu>
                </template>
                <!-- 支払チェック -->
                <template v-slot:[`item.sheet`]="{ item }">
                  <v-btn fab dark small color="green" @click="openSheet(item)">
                    <v-icon>
                      mdi-google-spreadsheet
                    </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card elevation="5">
            <v-card-title>検索</v-card-title>
            <v-card-text>
              <v-text-field @change="doSearch" clearable label="テキストを入力" v-model="search.text" />
              <v-text-field readonly clearable v-model="search.customerName" @click="selectSearchCustomerDialog=true" @click:clear="clearCustomerSearch" label="取引先" />
              <v-select @change="doSearch" clearable v-model="search.month" :items="allMonth" item-text="text" item-value="value" label="年月" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <v-dialog v-model="selectSearchCustomerDialog" max-width="1000">
      <v-card>
        <v-card-title>
          <v-text-field append-icon="mdi-magnify" label="検索" v-model="customerSearch" />
        </v-card-title>
        <v-card-text>
          <v-data-table
            height="400"
            @click:row="selectSearchCustomer"
            :search="customerSearch"
            :items="allAccount"
            :headers="customerTableHeaders"
            :items-per-page="-1"
            hide-default-footer
          ></v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import { getFirestore } from 'firebase/firestore'
import Invoice from '../model/invoice'
import Account from '../model/account';

export default {
  name: 'InvoiceList',
  data: () => ({
    loading: false,
    selectSearchCustomerDialog: false,
    search: {
      text: '',
      customer: undefined,
      customerName: undefined,
      month: undefined,
    },
    customerSearch: "",
    allInvoice: [],
    allAccount: [],
  }),
  methods: {
    getAllInvoice: async function() {
      this.loading = true;
      this.allInvoice = await Invoice.getAll(getFirestore());
      this.search = {
        customer: this.$route.query.customer,
        customerName: this.$route.query.customerName,
        month: this.$route.query.month,
        text: this.$route.query.text,
      };
      this.loading = false;
    },
    getAllAccount: async function() {
      this.allAccount = await Account.getAll(getFirestore());
    },
    checkBilling: async function(item) {
      if(confirm("保存しますか?")) {
        this.loading = true;
        await item.checkBilling(getFirestore());
        this.getAllInvoice();
      }
    },
    clearCheckBilling: async function(item) {
      if(confirm("保存しますか?")) {
        this.loading = true;
        item.billingCheck = "";
        await item.checkBilling(getFirestore());
        this.getAllInvoice();
      }
    },
    checkDeposit: async function(item) {
      if(confirm("保存しますか?")) {
        this.loading = true;
        await item.checkDeposit(getFirestore());
        this.getAllInvoice();
      }
    },
    clearCheckDeposit: async function(item) {
      if(confirm("保存しますか?")) {
        this.loading = true;
        item.depositCheck = "";
        await item.checkDeposit(getFirestore());
        this.getAllInvoice();
      }
    },
    doSearch: function() {
      console.log(this.search);
      this.$router.push({ path: '/invoice', query: {
        text: this.search.text,
        customerName: (this.search.customerName!="") ? this.search.customerName : undefined,
        customer: (this.search.customer!="") ? this.search.customer : undefined,
        month: this.search.month
      }});
    },
    selectSearchCustomer: function(item) {
      this.search.customerName = item.name;
      this.search.customer = item.id;
      this.selectSearchCustomerDialog = false;
      this.doSearch();
    },
    clearCustomerSearch: function() {
      this.search.customer = "";
      this.search.customerName = "";
      this.doSearch();
    },
    openSheet: function(item) {
      window.open('https://docs.google.com/spreadsheets/d/' + item.sheet);
    }
  },
  computed: {
    invoiceTableHeaders: function() {
      return [
        { text: "ID", value: "id", filter: (v,s, item) => {if(this.$route.query.month == undefined) return true; else if(item.id.split("-")[1].slice(0,4)==this.search.month) return true; else return false;} },
        { text: "年月", value: "date" },
        { text: "取引先", value: "customer", filter: (v,s, item) => {if(this.$route.query.customer == undefined) return true; else if(item.id.split("-")[0]==this.search.customer) return true; else return false;} },
        { text: "請求", value: "billingCheck" },
        { text: "入金", value: "depositCheck" },
        { text: "Excel", value: "sheet" },
      ]
    },
    allMonth: function() {
      const ret = [];
      this.allInvoice.forEach((invoice) => {
        const month = invoice.id.split("-")[1].slice(0,4);
        ret.push({
          text: Number(month.slice(0,2))+"年"+Number(month.slice(2,4))+"月",
          value: month,
        });
      })
      return ret;
    },
    allSearchAccount: function() {
      const ret = [];
      if(this.allInvoice.length > 0) {
        this.allInvoice.forEach((invoice) => {
          ret.push({
            text: (this.allAccount.length > 0) ? this.allAccount.find(account => account.id ==  invoice.id.split("-")[0]).name : '',
            value: invoice.id.split("-")[0],
          });
        });
      }
      return ret;
    },
    customerTableHeaders() {
      return [
        { text: 'ID', value: 'id' },
        { text: '顧客名', value: 'name' },
      ]
    },
  },
  created() {
    this.getAllInvoice();
    this.getAllAccount();
  },
};
</script>
