<template>
  <v-container style="height: 100%" fluid>

    <v-row v-if="loading" style="height: 100%" justify="center" align-content="center">
      <v-col>
        <v-row justify="center">
          <v-progress-circular color="grey darken-2" size="80" width="6" indeterminate />
        </v-row>
      </v-col>
    </v-row>

    <template v-else>
      <h1 class="mt-5 ms-5">仕入一覧</h1>
      <v-row class="mt-5">
        <v-col cols="12">
          <v-card elevation="5">
            <v-card-title>
              <v-text-field class="mx-3" @change="doSearch" clearable label="テキストを入力" v-model="search.text" />
              <v-text-field class="mx-3" readonly clearable v-model="search.supplierName" @click="selectSearchSupplierDialog=true" @click:clear="clearSupplierSearch" label="仕入先" />
              <v-select class="mx-3" @change="doSearch" clearable v-model="search.opp" :items="allMonth" item-text="text" item-value="value" label="入伝日" />
              <v-switch class="ms-5 mx-3" color="blue" label="合計表示" v-model="displaySum" />
            </v-card-title>
            <v-card-text>
              <v-data-table
                hide-default-footer
                :items-per-page="-1"
                :headers="oppProductTableHeaders"
                :items="allOppProduct"
                :search="$route.query.text"
                item-key="id+customerId"
              >
                <!-- 商談名 -->
                <template v-slot:[`item.opp`]="{ item }">
                  {{ (allOpp.length > 0) ? allOpp.find(opp => opp.id == item.oppId).name : "" }}
                </template>
                <!-- 商談名 -->
                <template v-slot:[`item.customer`]="{ item }">
                  {{ (allAccount.length > 0 && allOpp.length > 0) ? allAccount.find(account => account.id == allOpp.find(opp => opp.id == item.oppId).customerId).name : "" }}
                </template>
                <!-- 仕入先名 -->
                <template v-slot:[`item.supplierId`]="{ item }">
                  {{ (allAccount.length > 0 && allAccount.find(account => account.id ==  item.supplierId)) ? allAccount.find(account => account.id ==  item.supplierId) ? allAccount.find(account => account.id ==  item.supplierId).name : '' : '' }}
                </template>
                <!-- 数量 -->
                <template v-slot:[`item.quantity`]="{ item }">
                  {{ item.quantity + item.unit }}
                </template>
                <!-- 仕入金額 -->
                <template v-slot:[`item.supplyPrice`]="{ item }">
                  ¥{{ (item.supplyPrice).toLocaleString() }}
                </template>
                <!-- 税 -->
                <template v-slot:[`item.tax`]="{ item }">
                  {{ (item.supplyPrice*item.tax/100).toLocaleString() }}
                </template>
                <!-- 支払金額 -->
                <template v-slot:[`item.supplyPriceSum`]="{ item }">
                  ¥{{ (item.supplyPrice*(1+item.tax/100)).toLocaleString() }}
                </template>
                <!-- 入伝チェック -->
                <template v-slot:[`item.entryCheck`]="{ item }">
                  <v-menu
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="item.entryCheck"
                        prepend-icon="mdi-calendar"
                        clearable
                        @click:clear="clearEntryCheck(item)"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-card>
                      <v-date-picker
                        v-model="item.entryCheck"
                        locale="jp-ja"
                        :day-format="date => new Date(date).getDate()"
                        no-title
                        @change="entryCheck(item)"
                      ></v-date-picker>
                    </v-card>
                  </v-menu>
                </template>
                <!-- 支払いチェック -->
                <template v-slot:[`item.supplyCheck`]="{ item }">
                  <v-menu
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="item.supplyCheck"
                        prepend-icon="mdi-calendar"
                        clearable
                        @click:clear="clearSupplyCheck(item)"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-card>
                      <v-date-picker
                        v-model="item.supplyCheck"
                        locale="jp-ja"
                        :day-format="date => new Date(date).getDate()"
                        no-title
                        @change="supplyCheck(item)"
                      ></v-date-picker>
                    </v-card>
                  </v-menu>
                </template>
                <template v-slot:[`body.append`]="{ items }" v-if="displaySum">
                  <tr>
                    <td>合計</td>
                    <td />
                    <td />
                    <td />
                    <td class="text-h6">¥{{ items.reduce((sum, element) => sum + element.supplyPrice, 0).toLocaleString() }}</td>
                    <td class="text-h6">¥{{ items.reduce((sum, element) => sum + element.supplyPrice*0.1, 0).toLocaleString() }}</td>
                    <td class="text-h6">¥{{ items.reduce((sum, element) => sum + element.supplyPrice*1.1, 0).toLocaleString() }}</td>
                    <td />
                    <td />
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <v-dialog v-model="selectSearchSupplierDialog" max-width="1000">
      <v-card>
        <v-card-title>
          <v-text-field append-icon="mdi-magnify" label="検索" v-model="supplierSearch" />
        </v-card-title>
        <v-card-text>
          <v-data-table
            height="400"
            @click:row="selectSearchSupplier"
            :search="supplierSearch"
            :items="allAccount.filter((account) => account.kind.supplier)"
            :headers="supplierTableHeaders"
            :items-per-page="-1"
            hide-default-footer
          ></v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import { getFirestore } from 'firebase/firestore'

import Opp from '../model/opp';
import Account from '../model/account';
import OppProduct from '../model/oppProduct';

export default {
  name: 'ProductList',
  data: () => ({
    selectSearchSupplierDialog: false,
    loading: false,
    displaySum: false,
    search: {
      text: '',
      displaySum: false,
      supplier: undefined,
      supplierName: undefined,
      opp: undefined,
    },
    supplierSearch: "",
    // インスタンス配列
    allOpp: [],
    allOppProduct: [],
    allAccount: []
  }),
  methods: {
    getAllOpp: async function() {
      this.allOpp = await Opp.getAll(getFirestore());
    },
    getAllOppProduct: async function() {
      this.loading = true;
      this.allOppProduct = await OppProduct.getAll(getFirestore());
      this.search = {
        opp: this.$route.query.opp,
        supplier: this.$route.query.supplier,
        supplierName: this.$route.query.supplierName,
        text: this.$route.query.text,
      };
      this.loading = false;
    },
    getAllAccount: async function() {
      this.allAccount = await Account.getAll(getFirestore());
    },
    doSearch: function() {
      this.$router.push({ path: '/supply', query: {
        text: this.search.text,
        opp: this.search.opp,
        supplierName: (this.search.supplierName!="") ? this.search.supplierName : undefined,
        supplier: (this.search.supplier!="") ? this.search.supplier : undefined,
      }});
    },
    entryCheck: async function(item) {
      if(confirm("保存しますか?")) {
        this.loading = true;
        await item.update(getFirestore(), {
          "entryCheck": item.entryCheck,
        });
        this.getAllOppProduct();
      }
    },
    clearEntryCheck: async function(item) {
      if(confirm("保存しますか?")) {
        this.loading = true;
        await item.update(getFirestore(), {
          "entryCheck": "",
        });
        this.getAllOppProduct();
      }
    },
    supplyCheck: async function(item) {
      if(confirm("保存しますか?")) {
        this.loading = true;
        await item.check(getFirestore(), item.oppId);
        this.getAllOppProduct();
      }
    },
    clearSupplyCheck: async function(item) {
      if(confirm("保存しますか?")) {
        this.loading = true;
        item.supplyCheck = "";
        await item.check(getFirestore(), item.oppId);
        this.getAllOppProduct();
      }
    },
    selectSearchSupplier: function(item) {
      this.search.supplierName = item.name;
      this.search.supplier = item.id;
      this.selectSearchSupplierDialog = false;
      this.doSearch();
    },
    clearSupplierSearch: function() {
      this.search.supplier = "";
      this.search.supplierName = "";
      this.doSearch();
    }
  },
  computed: {
    oppProductTableHeaders() {
      return [
        { text: '商談番号', value: 'oppId' },
        { text: '仕入先', value: 'supplierId', filter: supplierId => {if(this.$route.query.supplier == undefined) return true; else if(supplierId==this.$route.query.supplier) return true; else return false;} },
        { text: '商品名', value: 'name' },
        { text: '数量', value: 'quantity' },
        { text: '仕入価格', value: 'supplyPrice' },
        { text: '(税)', value: 'tax' },
        { text: '支払金額', value: 'supplyPriceSum' },
        { text: '入伝', value: 'entryCheck', filter: entryCheck => {if(this.$route.query.opp == undefined) return true; else if(entryCheck != "" && entryCheck != null && entryCheck.split("-")[0]+"-"+entryCheck.split("-")[1] == this.$route.query.opp) return true; else return false;} },
        { text: '支払い', value: 'supplyCheck' },
      ]
    },
    supplierTableHeaders() {
      return [
        { text: 'ID', value: 'id' },
        { text: '顧客名', value: 'name' },
      ]
    },    
    allMonth: function() {
      const ret = [];
      this.allOppProduct.forEach((product) => {
        if(product.entryCheck != "" && product.entryCheck != null) {
          ret.push({
            text: product.entryCheck.split("-")[0] + "年" + product.entryCheck.split("-")[1] + "月",
            value: product.entryCheck.split("-")[0] + "-" + product.entryCheck.split("-")[1]
          });
        }
      })
      return ret;
    }
  },
  created() {
    this.getAllOppProduct();
    this.getAllOpp();
    this.getAllAccount();
  },
};
</script>
