<template>
  <v-container fluid>
    <h1 class="mt-5 ms-5">ダッシュボード</h1>
    <v-row class="mt-5">
      <v-col cols="7">
        <v-row>
          <v-col cols="12">
            <v-card elevation="5" height="200">
              <v-container style="height: 100%">
                <v-row justify="center" align-content="center" style="height: 100%">
                  <v-col cols="12" class="text-center text-h5 font-weight-black">
                    こんにちは
                  </v-col>
                  <v-col cols="12" class="text-center text-h5 font-weight-black">
                    {{user.displayName}}さん
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card elevation="5" height="400">
              <v-card-title>直近の商談</v-card-title>
              <v-card-text>
                <v-data-table
                  hide-default-footer
                  @click:row="(item) => $router.push('/opp/' + item.id)"
                  :headers="[
                    { text: 'ID', value: 'id' },
                    { text: '商談名', value: 'name' },
                    { text: '取引先', value: 'customer.name' },
                  ]"
                  :items="latestOpp"
                ></v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card elevation="5" height="400">
              <v-card-title>利益</v-card-title>
              <v-card-text>
                <v-data-table
                  hide-default-footer
                  :headers="[
                    { text: '月', value: 'month' },
                    { text: '受注ベース', value: 'salesByOrder' },
                    { text: '支払いベース', value: 'salesByPayment' },
                  ]"
                  :items="profit"
                >
                  <!-- 受注ベース -->
                  <template v-slot:[`item.salesByOrder`]="{ item }">
                    ￥{{ item.salesByOrder.toLocaleString() }}
                  </template>
                  <!-- 受注ベース -->
                  <template v-slot:[`item.salesByPayment`]="{ item }">
                    ￥{{ item.salesByPayment.toLocaleString() }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="5">
        <v-card elevation="5" height="600">
          <v-card-title>月間売上</v-card-title>
          <v-card-text >
            <monthly-sales :height="230" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore';

import MonthlySales from '../components/MonthlySales.vue'

import Opp from '../model/opp';

export default {
  name: 'Dashboard',
  components: { MonthlySales },
  data: () => ({
    monthySales: "",
    latestOpp: [],
    profit: [],
    user: {}
  }),
  async created() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if(user) {
        this.user = user;
      } else {
        this.user = null;
      }
    });
    this.latestOpp = await Opp.getLatest(getFirestore());
  },
  async mounted() {
    const allOpp = await Opp.getAll(getFirestore());

    function getSalesByOrderDate(orderDate) {
      let sales = 0;
      allOpp.forEach(opp => {
        if(opp.id.split("-")[0] == orderDate) {
          opp.product.forEach(product => {
            sales += product.quantity * product.unitPrice * (1+product.tax/100); 
          });
        }
      });
      return sales;
    }

    function getSalesByPaymentDate(paymentDate) {
      let sales = 0;
      allOpp.forEach(opp => {
        const tmpPayment = opp.paymentDate.split("-")[0].slice(-2) + opp.paymentDate.split("-")[1];
        if(tmpPayment == paymentDate) {
          opp.product.forEach(product => {
            sales += product.quantity * product.unitPrice * (1+product.tax/100);
          });
        }
      });
      return sales;
    }

    let now = new Date();
    for(let i=0; i<5; i++) {
      const date = now.getFullYear().toString().slice(-2) + (now.getMonth() + 1).toString().padStart(2, "0");
      const tmp = {
        "month": now.getFullYear().toString().slice(-2) + "年" + (now.getMonth() + 1).toString() + "月",
        "salesByOrder": getSalesByOrderDate(date),
        "salesByPayment": getSalesByPaymentDate(date),
      }
      this.profit.push(tmp);
      now.setMonth(now.getMonth() - 1);
    }
  }
}
</script>
