var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"height":"100%"},attrs:{"fluid":""}},[(_vm.loading)?_c('v-row',{staticStyle:{"height":"100%"},attrs:{"justify":"center","align-content":"center"}},[_c('v-col',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-progress-circular',{attrs:{"color":"grey darken-2","size":"80","width":"6","indeterminate":""}})],1)],1)],1):[_c('h1',{staticClass:"mt-5 ms-5"},[_vm._v("仕入一覧")]),_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"5"}},[_c('v-card-title',[_c('v-text-field',{staticClass:"mx-3",attrs:{"clearable":"","label":"テキストを入力"},on:{"change":_vm.doSearch},model:{value:(_vm.search.text),callback:function ($$v) {_vm.$set(_vm.search, "text", $$v)},expression:"search.text"}}),_c('v-text-field',{staticClass:"mx-3",attrs:{"readonly":"","clearable":"","label":"仕入先"},on:{"click":function($event){_vm.selectSearchSupplierDialog=true},"click:clear":_vm.clearSupplierSearch},model:{value:(_vm.search.supplierName),callback:function ($$v) {_vm.$set(_vm.search, "supplierName", $$v)},expression:"search.supplierName"}}),_c('v-select',{staticClass:"mx-3",attrs:{"clearable":"","items":_vm.allMonth,"item-text":"text","item-value":"value","label":"入伝日"},on:{"change":_vm.doSearch},model:{value:(_vm.search.opp),callback:function ($$v) {_vm.$set(_vm.search, "opp", $$v)},expression:"search.opp"}}),_c('v-switch',{staticClass:"ms-5 mx-3",attrs:{"color":"blue","label":"合計表示"},model:{value:(_vm.displaySum),callback:function ($$v) {_vm.displaySum=$$v},expression:"displaySum"}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","items-per-page":-1,"headers":_vm.oppProductTableHeaders,"items":_vm.allOppProduct,"search":_vm.$route.query.text,"item-key":"id+customerId"},scopedSlots:_vm._u([{key:"item.opp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((_vm.allOpp.length > 0) ? _vm.allOpp.find(function (opp) { return opp.id == item.oppId; }).name : "")+" ")]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((_vm.allAccount.length > 0 && _vm.allOpp.length > 0) ? _vm.allAccount.find(function (account) { return account.id == _vm.allOpp.find(function (opp) { return opp.id == item.oppId; }).customerId; }).name : "")+" ")]}},{key:"item.supplierId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((_vm.allAccount.length > 0 && _vm.allAccount.find(function (account) { return account.id == item.supplierId; })) ? _vm.allAccount.find(function (account) { return account.id == item.supplierId; }) ? _vm.allAccount.find(function (account) { return account.id == item.supplierId; }).name : '' : '')+" ")]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantity + item.unit)+" ")]}},{key:"item.supplyPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" ¥"+_vm._s((item.supplyPrice).toLocaleString())+" ")]}},{key:"item.tax",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.supplyPrice*item.tax/100).toLocaleString())+" ")]}},{key:"item.supplyPriceSum",fn:function(ref){
var item = ref.item;
return [_vm._v(" ¥"+_vm._s((item.supplyPrice*(1+item.tax/100)).toLocaleString())+" ")]}},{key:"item.entryCheck",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","clearable":"","readonly":""},on:{"click:clear":function($event){return _vm.clearEntryCheck(item)}},model:{value:(item.entryCheck),callback:function ($$v) {_vm.$set(item, "entryCheck", $$v)},expression:"item.entryCheck"}},'v-text-field',attrs,false),on))]}}],null,true)},[_c('v-card',[_c('v-date-picker',{attrs:{"locale":"jp-ja","day-format":function (date) { return new Date(date).getDate(); },"no-title":""},on:{"change":function($event){return _vm.entryCheck(item)}},model:{value:(item.entryCheck),callback:function ($$v) {_vm.$set(item, "entryCheck", $$v)},expression:"item.entryCheck"}})],1)],1)]}},{key:"item.supplyCheck",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","clearable":"","readonly":""},on:{"click:clear":function($event){return _vm.clearSupplyCheck(item)}},model:{value:(item.supplyCheck),callback:function ($$v) {_vm.$set(item, "supplyCheck", $$v)},expression:"item.supplyCheck"}},'v-text-field',attrs,false),on))]}}],null,true)},[_c('v-card',[_c('v-date-picker',{attrs:{"locale":"jp-ja","day-format":function (date) { return new Date(date).getDate(); },"no-title":""},on:{"change":function($event){return _vm.supplyCheck(item)}},model:{value:(item.supplyCheck),callback:function ($$v) {_vm.$set(item, "supplyCheck", $$v)},expression:"item.supplyCheck"}})],1)],1)]}},(_vm.displaySum)?{key:"body.append",fn:function(ref){
var items = ref.items;
return [_c('tr',[_c('td',[_vm._v("合計")]),_c('td'),_c('td'),_c('td'),_c('td',{staticClass:"text-h6"},[_vm._v("¥"+_vm._s(items.reduce(function (sum, element) { return sum + element.supplyPrice; }, 0).toLocaleString()))]),_c('td',{staticClass:"text-h6"},[_vm._v("¥"+_vm._s(items.reduce(function (sum, element) { return sum + element.supplyPrice*0.1; }, 0).toLocaleString()))]),_c('td',{staticClass:"text-h6"},[_vm._v("¥"+_vm._s(items.reduce(function (sum, element) { return sum + element.supplyPrice*1.1; }, 0).toLocaleString()))]),_c('td'),_c('td')])]}}:null],null,true)})],1)],1)],1)],1)],_c('v-dialog',{attrs:{"max-width":"1000"},model:{value:(_vm.selectSearchSupplierDialog),callback:function ($$v) {_vm.selectSearchSupplierDialog=$$v},expression:"selectSearchSupplierDialog"}},[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"検索"},model:{value:(_vm.supplierSearch),callback:function ($$v) {_vm.supplierSearch=$$v},expression:"supplierSearch"}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"height":"400","search":_vm.supplierSearch,"items":_vm.allAccount.filter(function (account) { return account.kind.supplier; }),"headers":_vm.supplierTableHeaders,"items-per-page":-1,"hide-default-footer":""},on:{"click:row":_vm.selectSearchSupplier}})],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }