<template>
  <v-container style="height: 100%" fluid>
    <v-row style="height: 100%" justify="center" align-content="center">
      <v-col>
        <v-row justify="center">
          <h1>404 Not Found</h1>
        </v-row>
        <v-row class="mt-5" justify="center">
          <h4>このURLは存在しません</h4>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "NotFound",
  methods: {
  },
};
</script>
