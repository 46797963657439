import * as firestore from "firebase/firestore"
import Product from './product';
import Address from './address';

class Account {
  constructor(docId, data) {
    this.docId = docId;
    this.id = data.id;
    this.name = data.name;
    this.kind = data.kind;
    this.dateRule = data.dateRule;
    this.address = data.address;
    this.product = [];
    this.sending = [];
  }

  static getInitJson() {
    return {
      id: 0,
      name: "",
      kind: {
        bni: false,
        customer: false,
        supplier: false
      },
      dateRule: {
        bill: {
          month: "当月",
          date: "20",
        },
        payment: {
          month: "翌月",
          date: "25",
        },
      },
      address: {
        postel: "",
        address1: "",
        address2: "",
      },
      sending: new Array(),
    }
  }

  static async create(db, data) {
    const ref = firestore.collection(db, "account");
    const q = firestore.query(ref, firestore.orderBy("id", "desc"), firestore.limit(1));
    const snapshot = await firestore.getDocs(q);
    console.log(snapshot.size);
    snapshot.forEach((e) => {
      console.log(e.data());
      data['id'] = e.data().id + 1;
    });
    await firestore.addDoc(ref, data);
  }

  static async get(db, id) {
    let ret = null;
    const ref = firestore.collection(db, "account");
    const q = firestore.query(ref, firestore.where("id", "==", id), firestore.limit(1));
    const snapshot = await firestore.getDocs(q);
    snapshot.forEach((e) => {
      ret = new this(e.id, e.data());
    });
    if(id == 0) return;
    ret.sending = await Address.get(db, id);
    return ret;
  }

  static async getAll(db) {
    const ret = new Array();
    const ref = firestore.collection(db, "account");
    const q = firestore.query(ref, firestore.orderBy("id"));
    const snapshot = await firestore.getDocs(q);
    snapshot.forEach((e) => {
      ret.push(new this(e.id, e.data()));
    });
    return ret;
  }

  async addProduct(db, data) {
    let id = "0001";
    const ref = firestore.collection(db, "product");
    const q = firestore.query(ref, firestore.where("customerId", "==", this.id), firestore.orderBy("id", "desc"), firestore.limit(1));
    const snapshot = await firestore.getDocs(q);
    snapshot.forEach((e) => {
      id = (Number(e.data().id)+1).toString().padStart(4, "0");
    });
    data["id"] = id;
    data["customerId"] = this.id;
    await firestore.addDoc(ref, data);
  }

  async getProduct(db) {
    const ref = firestore.collection(db, "product");
    const q = firestore.query(ref, firestore.where("customerId", "==", this.id), firestore.orderBy("id"));
    const snapshot = await firestore.getDocs(q);
    snapshot.forEach((e) => {
      this.product.push(new Product(e.id, e.data()));
    });
  }

  toJson() {
    const ret = JSON.parse(JSON.stringify(this));
    delete ret["docId"];
    delete ret["product"];
    return ret;
  }

  async edit(db, data) {
    const ref = firestore.doc(db, "account", this.docId);
    await firestore.setDoc(ref, data);
  }

  async delete(db) {
    const ref = firestore.doc(db, "account", this.docId);
    await firestore.deleteDoc(ref);
  }
}

export default Account;