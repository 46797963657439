<template>
  <v-navigation-drawer app permanent>
    <v-list>
      <v-list-item>
        <v-list-item-avatar>
          <v-img v-if="user" :src="user.photoURL" />
          <v-icon large v-else dark class="grey darken-1" v-text="'mdi-account'" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-if="user" v-text="user.displayName" />
          <v-list-item-title v-else v-text="'ゲスト'" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-btn v-if="user" small color="error" @click="logout">ログアウト</v-btn>
        <v-btn v-else small color="success" @click="login">ログイン</v-btn>
      </v-list-item>      
      <v-divider />

      <v-list-item-group>
        <!-- Main Menu -->
        <v-subheader>メインメニュー</v-subheader>      
        <v-list-item v-for="(item, i) in mainMenuItems" :key="`first-${i}`" :to="item.link">
          <v-list-item-icon>
            <v-icon v-text="item.icon" />
          </v-list-item-icon>
          <v-list-item-title v-text="item.text" />
        </v-list-item>
        <v-divider />
        <!-- Accounting Menu -->
        <v-subheader>経理</v-subheader>      
        <v-list-item v-for="(item, i) in accountingMenu" :key="`second-${i}`" :to="item.link">
          <v-list-item-icon>
            <v-icon v-text="item.icon" />
          </v-list-item-icon>
          <v-list-item-title v-text="item.text" />
        </v-list-item>
        <v-divider />
        <!-- Developer Menu -->
        <v-subheader>for Developer</v-subheader>      
        <v-list-item v-for="(item, i) in developerMenu" :key="`third-${i}`" :to="item.link">
          <v-list-item-icon>
            <v-icon v-text="item.icon" />
          </v-list-item-icon>
          <v-list-item-title v-text="item.text" />
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { getAuth, onAuthStateChanged } from 'firebase/auth'

export default {
  name: 'SideMenu',

  data: () => ({
    user: {},
    mainMenuItems: [
      { text: 'ダッシュボード', icon: 'mdi-view-dashboard', link: '/' },
      { text: '商談', icon: 'mdi-clipboard-text', link: '/opp' },
      { text: '取引先', icon: 'mdi-account', link: '/account' },
      { text: '商品', icon: 'mdi-archive', link: '/product' },
    ],
    accountingMenu: [
      { text: '請求書', icon: 'mdi-receipt', link: '/invoice' },
      { text: '仕入れ', icon: 'mdi-truck-delivery', link: '/supply' },
    ],
    developerMenu: [
      { text: 'Labs', icon: 'mdi-chandelier', link: '/labs' }
    ]
  }),
  methods: {
    login: function() {
      if(this.$route.path != '/login') {
        this.$router.push('/login');
      }
    },
    logout: async function() {
      if(confirm("ログアウトしますか?")) {
        const auth = getAuth();
        await auth.signOut();
      }
    }
  },
  created() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if(user) {
        this.user = user;
      } else {
        this.user = null;
      }
    });
  }
};</script>
