import * as firestore from "firebase/firestore"
import Account from './account';
import Opp from "./opp";

class OppProduct {
  constructor(docId, data) {
    this.docId = docId;
    this.id = data.id;
    this.tax = data.tax;
    this.oppId = data.oppId;
    this.templateId = data.templateId;
    this.name = data.name;
    this.supplierId = data.supplierId;
    this.supplierName = data.supplierName;
    this.unitPrice = data.unitPrice;
    this.unit = data.unit;
    this.quantity = data.quantity;
    this.supplyPrice = data.supplyPrice;
    this.specification = data.specification;
    this.supplyCheck = data.supplyCheck;
    this.entryCheck = data.entryCheck;
  }

  static async create(db, data) {
    let tmpId = "0001";
    const ref = firestore.collection(db, "oppProduct");
    const q = firestore.query(ref, firestore.where("oppId", "==", data.oppId), firestore.orderBy("id", "desc"), firestore.limit(1));
    const snapshot = await firestore.getDocs(q);
    snapshot.forEach((e) => {
      tmpId = (Number(e.data().id)+1).toString().padStart(4, "0");
    });
    data['id'] = tmpId;
    await firestore.addDoc(ref, data);
  }

  static async get(db, oppId) {
    let ret = [];
    const ref = firestore.collection(db, "oppProduct");
    const q = firestore.query(ref, firestore.where("oppId", "==", oppId), firestore.orderBy("id"));
    const snapshot = await firestore.getDocs(q);
    snapshot.forEach((e) => {
      ret.push(new this(e.id, e.data()));
    });
    for(let i=0; i<ret.length; i++) {
      ret[i].supplier = await Account.get(db, ret[i].supplierId);
    }
    return ret;
  }

  static async getAll(db) {
    const ret = [];
    const ref = firestore.collection(db, "oppProduct");
    const q = firestore.query(ref, firestore.orderBy("oppId", "desc"), firestore.orderBy("id", "desc"));
    const snapshot = await firestore.getDocs(q);
    snapshot.forEach((e) => {
      ret.push(new this(e.id, e.data()));
    });
    return ret;
  }

  async edit(db, data) {
    const ref = firestore.doc(db, "oppProduct", this.docId);
    await firestore.setDoc(ref, data);
  }

  async update(db, data) {
    const ref = firestore.doc(db, "oppProduct", this.docId);
    await firestore.updateDoc(ref, data);
  }

  async delete(db) {
    const ref = firestore.doc(db, "oppProduct", this.docId);
    await firestore.deleteDoc(ref);
  }

  static async getMonthlySales(db) {
    const now = new Date();
    const keys = new Array();
    const values = new Array(5).fill(0);
    for(let i=0; i<5; i++) {
      keys.unshift(now.getFullYear().toString().slice(-2)+(now.getMonth()+1).toString().padStart(2,"0"));
      now.setMonth(now.getMonth()-1);
    }
    const ref = firestore.collection(db, "oppProduct");
    const q = firestore.query(ref, firestore.orderBy("id"));
    const snapshot = await firestore.getDocs(q);
    snapshot.forEach((e) => {
      keys.forEach((key, ind) => {
        if(key == String(e.data().oppId).split("-")[0]) {
          values[ind] += e.data().unitPrice * e.data().quantity;
        }
      });
    });
    return values;
  }

  async check(db, oppId) {
    let allCheck = true;
    const colRef = firestore.collection(db, "oppProduct");
    if(this.supplyCheck != "") {
      const q = firestore.query(colRef, firestore.where("oppId", "==", oppId));
      const snapshot = await firestore.getDocs(q);
      snapshot.forEach((e) => {
        if(e.id != this.docId && e.data().supplyCheck == "") allCheck = false;
      });
    } else allCheck = false;
    const opp = await Opp.get(db, oppId);
    opp.update(db, {
      "phase.payment": allCheck,
    });
    console.log(allCheck);
    const docRef = firestore.doc(db, "oppProduct", this.docId);
    await firestore.updateDoc(docRef, {
      supplyCheck: this.supplyCheck,
    });
  }

  toJson() {
    const ret = JSON.parse(JSON.stringify(this));
    delete ret['docId'];
    delete ret['supplier'];
    return ret;
  }

  static getInitJson() {
    return {
      id: "",
      tax: 10,
      oppId: "",
      templateId: "",
      name: "",
      supplierId: 134000,
      unitPrice: 0,
      unit: "",
      quantity: 0,
      supplyPrice: 0,
      specification: "",
      supplyCheck: "",
      entryCheck: "",
    }
  }

}

export default OppProduct;