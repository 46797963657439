<script>
import { Bar } from 'vue-chartjs';
import { getFirestore } from 'firebase/firestore'
import Opp from '../model/opp';

export default {
  extends: Bar,
  name: 'MonthlySales',
  data: () => ({
    data: {
      labels: ['', '', '', '', ''],
      datasets: [
        {
          label: '受注ベース',
          data: [0, 0, 0, 0, 0],
          backgroundColor: 'rgb(255, 99, 132)',
        },
        {
          label: '支払いベース',
          data: [0, 0, 0, 0, 0],
          backgroundColor: 'rgb(99, 132, 255)',
        },
      ]
    },
    options: {
    }
  }),
  async mounted () {
    const allOpp = await Opp.getAll(getFirestore());

    function getSalesByOrderDate(orderDate) {
      let sales = 0;
      allOpp.forEach(opp => {
        if(opp.id.split("-")[0] == orderDate) {
          opp.product.forEach(product => {
            sales += product.quantity * product.unitPrice * (1+product.tax/100); 
          });
        }
      });
      return sales;
    }

    function getSalesByPaymentDate(paymentDate) {
      let sales = 0;
      allOpp.forEach(opp => {
        const tmpPayment = opp.paymentDate.split("-")[0].slice(-2) + opp.paymentDate.split("-")[1];
        if(tmpPayment == paymentDate) {
          opp.product.forEach(product => {
            sales += product.quantity * product.unitPrice * (1+product.tax/100);
          });
        }
      });
      return sales;
    }

    let now = new Date();
    for(let i=0; i<5; i++) {
      const date = now.getFullYear().toString().slice(-2) + (now.getMonth() + 1).toString().padStart(2, "0");
      this.data.labels[4-i] = now.getFullYear().toString().slice(-2) + "年" + (now.getMonth() + 1).toString() + "月";
      this.data.datasets[0].data[4-i] = getSalesByOrderDate(date);
      this.data.datasets[1].data[4-i] = getSalesByPaymentDate(date);
      now.setMonth(now.getMonth() - 1);
    }

    this.renderChart(this.data, this.options);
  }
}
</script>