<template>
  <v-container style="height: 100%"  fluid>

    <v-row v-if="loading" style="height: 100%" justify="center" align-content="center">
      <v-col>
        <v-row justify="center">
          <v-progress-circular color="grey darken-2" size="80" width="6" indeterminate />
        </v-row>
      </v-col>
    </v-row>

    <template v-else>
      <h1 class="mt-5 ms-5">商品詳細</h1>
      <v-row class="mt-5">
        <v-col cols="12">
          <v-row>
            <v-col cols="6">
              <v-card elevation="5">
                <v-card-actions>
                  <v-card-title>基本情報</v-card-title>
                  <v-spacer />
                  <v-btn v-if="!edit" class="me-8" color="primary" @click="startEditProduct">編集</v-btn>
                  <template v-else>
                    <v-btn color="error" @click="cancelEditProduct">中止</v-btn>
                    <v-btn class="me-8" color="primary" @click="saveEditProduct">保存</v-btn>
                  </template>
                </v-card-actions>
                <v-card-text>
                  <v-container>
                    <v-row justify="center">
                      <v-col cols="4">
                        <v-text-field outlined readonly v-model="$route.params.id" label="ID" />
                      </v-col>
                      <v-col cols="8">
                        <v-text-field outlined append-icon="mdi-arrow-right-circle" @click:append="$router.push('/account/'+product.customerId)" readonly v-model="customerName" label="取引名" />
                      </v-col>
                      <v-col cols="9">
                        <v-text-field outlined :readonly="!edit" :filled="edit" v-model="editProduct.name" label="商品名" />
                      </v-col>
                      <v-col cols="3">
                        <v-text-field outlined :readonly="!edit" :filled="edit" v-model="editProduct.unit" label="単位" />
                      </v-col>
                      <v-col cols="12">
                        <v-textarea outlined :readonly="!edit" :filled="edit" v-model="editProduct.specification" label="仕様" />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6">
              <drive :idProps="editProduct.folderId" @createFolder="createFolder" />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-card elevation="5">
            <v-card-title>{{editProduct.name}}</v-card-title>
            <v-card-subtitle>価格表</v-card-subtitle>
            <v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn @click="confirmSelectedPrices" v-text="'見積書作成'" color="success" />
                <v-btn @click="startCreatePrice" color="success">作成</v-btn>
              </v-card-actions>
              <v-data-table
                hide-default-footer
                :items-per-page="-1"
                :headers="[
                  { text: '', value: 'selectNum' },
                  { text: '数量', value: 'quantity' },
                  { text: '単価', value: 'unitPrice' },
                  { text: '販売金額', value: 'salesPrice' },
                  { text: '税率', value: 'tax' },
                  { text: '仕入先', value: 'supplierId' },
                  { text: '仕入価格', value: 'supplyPrice' },
                  { text: '利益', value: 'profitPrice' },
                  { text: '利益率', value: 'profitRate' },
                  { text: '操作', value: 'actions' },
                ]"
                :items="(product) ? product.price : []"
              >
                <!-- selectNum -->
                <template v-slot:[`item.selectNum`]="{ item }">
                  <v-btn v-if="selectedPrices.includes(item)" @click="clickPriceCheckbox(item)" v-text="selectedPrices.indexOf(item)+1" color="error" />
                  <v-btn v-else @click="clickPriceCheckbox(item)" v-text="'追加'" color="success" />
                </template>
                <!-- 数量 -->
                <template v-slot:[`item.quantity`]="{ item }">
                  {{ item.quantity }}{{ product.unit }}
                </template>
                <!-- 販売金額 -->
                <template v-slot:[`item.salesPrice`]="{ item }">
                  ￥{{ (item.quantity * item.unitPrice).toLocaleString() }}
                </template>
                <!-- 税率 -->
                <template v-slot:[`item.tax`]="{ item }">
                  {{ item.tax }}%
                </template>
                <!-- 仕入先 -->
                <template v-slot:[`item.supplierId`]="{ item }">
                  {{ (allAccount.length > 0) ? allAccount.find(account => account.id ==  item.supplierId).name : '' }}
                </template>
                <!-- 利益 -->
                <template v-slot:[`item.profitPrice`]="{ item }">
                  ￥{{ item.quantity * item.unitPrice - item.supplyPrice }}
                </template>
                <!-- 利益率 -->
                <template v-slot:[`item.profitRate`]="{ item }">
                  {{ Math.floor((item.quantity * item.unitPrice - item.supplyPrice) / (item.quantity * item.unitPrice) * 10000) / 100 }}%
                </template>
                <!-- 操作 -->
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small @click.stop="startEditPrice(item)" v-text="'mdi-pencil'" />
                  <v-icon small @click.stop="deletePrice(item)" v-text="'mdi-delete'" />
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <v-dialog v-model="editPriceDialog" max-width="800">
      <v-card elevation="5">
        <v-card-title>価格{{ (createPrice) ? '作成': '編集'}}</v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="mt-3" justify="center">
              <v-col cols="5">
                <v-text-field outlined filled type="number" v-model.number="editPrice.unitPrice" label="単価" />
              </v-col>
              <v-col cols="5">
                <v-text-field outlined filled type="number" v-model.number="editPrice.quantity" label="数量" />
              </v-col>
              <v-col cols="2">
                <v-text-field outlined filled type="number" suffix="%" v-model.number="editPrice.tax" label="税率" />
              </v-col>
              <v-col cols="7">
                <v-autocomplete outlined filled v-model.number="editPrice.supplierId" :items="allAccount.filter((e) => e.kind.supplier)" item-text="name" item-value="id" label="仕入先" />
              </v-col>
              <v-col cols="5">
                <v-text-field outlined filled type="number" v-model.number="editPrice.supplyPrice" label="仕入価格" />
              </v-col>
              <v-col class="mt-8" cols="8">
                <v-btn @click="saveEditPrice" class="success" block>保存</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="quotationDialog" max-width="800">
      <v-card elevation="5">
        <v-card-title>見積書作成</v-card-title>
        <v-card-text>
          <v-data-table
            hide-default-footer
            :items-per-page="-1"
            :headers="[
              { text: '', value: 'selectNum' },
              { text: '数量', value: 'quantity' },
              { text: '単価', value: 'unitPrice' },
              { text: '税率', value: 'tax' },
              { text: '販売金額', value: 'salesPrice' },
              { text: '仕入先', value: 'supplierId' },
              { text: '仕入価格', value: 'supplyPrice' },
              { text: '利益', value: 'profitPrice' },
              { text: '利益率', value: 'profitRate' },
            ]"
            :items="selectedPrices"
          >
            <!-- 数量 -->
            <template v-slot:[`item.quantity`]="{ item }">
              {{ item.quantity }}{{ product.unit }}
            </template>
            <!-- 販売金額 -->
            <template v-slot:[`item.salesPrice`]="{ item }">
              ￥{{ (item.quantity * item.unitPrice).toLocaleString() }}
            </template>
            <!-- 税率 -->
            <template v-slot:[`item.tax`]="{ item }">
              {{ item.tax }}%
            </template>
            <!-- 仕入先 -->
            <template v-slot:[`item.supplierId`]="{ item }">
              {{ (allAccount.length > 0) ? allAccount.find(account => account.id ==  item.supplierId).name : '' }}
            </template>
            <!-- 利益 -->
            <template v-slot:[`item.profitPrice`]="{ item }">
              ￥{{ item.quantity * item.unitPrice - item.supplyPrice }}
            </template>
            <!-- 利益率 -->
            <template v-slot:[`item.profitRate`]="{ item }">
              {{ Math.floor((item.quantity * item.unitPrice - item.supplyPrice) / (item.quantity * item.unitPrice) * 10000) / 100 }}%
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn color="success" @click="createQuotation" block v-text="'作成'" />
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import { getFirestore } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions';
import Drive from '../components/Drive.vue'
import Account from '../model/account'
import Product from '../model/product'

export default {
  name: 'ProductPage',
  components: { Drive },
  data: () => ({
    // 編集ダイアログ
    editPriceDialog: false,
    createPrice: false,
    quotationDialog: false,
    loading: false,
    // 編集のフラグ
    edit: false,
    // タブ
    tab: 0,
    // 編集
    editProduct: {},
    editPriceIndex: -1,
    editPrice: {},
    // インスタンス
    product: null,
    allAccount: [],
    selectedPrices: []
  }),
  methods: {
    getAllAccount: async function() {
      this.allAccount = await Account.getAll(getFirestore());
    },
    getProduct: async function() {
      this.loading = true;
      const customerId = Number(String(this.$route.params.id).split('-')[0]);
      const id = String(this.$route.params.id).split('-')[1];
      this.product = await Product.get(getFirestore(), customerId, id);
      if(this.product) {
        this.editProduct = this.product.toJson();
        this.loading = false;
      } else {
        alert("商品が存在しません");
        this.$router.push('/product');
      }
    },
    startEditProduct: function() {
      this.edit = true;
    },
    cancelEditProduct: async function() {
      if(confirm("キャンセルしますか?")) {
        this.getProduct();
        this.edit = false;
      }
    },
    saveEditProduct: async function() {
      if(confirm("保存しますか?")) {
        this.loading = true;
        await this.product.edit(getFirestore(), this.editProduct);
        await this.product.updateFolder(getFirestore(), getFunctions(), this.editProduct);
        this.getProduct();
        this.edit = false;
        this.loading = false;
      }
    },
    startCreatePrice: function() {
      this.editPrice = Product.getInitPriceJson();
      this.editPriceDialog = true;
      this.createPrice = true;
    },
    startEditPrice: function(item) {
      const data = this.product.toJson();
      data.price.forEach((e, ind) => {
        if(JSON.stringify(e) === JSON.stringify(item)) {
          this.editPriceIndex = ind;
        }
      });
      this.editPrice = Object.assign({}, item);
      this.editPriceDialog = true;
    },
    cancelEditPrice: async function() {
      if(confirm("キャンセルしますか?")) {
        this.getProduct();
        this.editPriceDialog = false;
      }
    },
    saveEditPrice: async function() {
      if(confirm("保存しますか?")) {
        delete this.editPrice['id'];
        if(this.createPrice) {
          const data = this.product.toJson();
          data.price.push(this.editPrice);
          await this.product.edit(getFirestore(), data);
        } else {
          const data = this.product.toJson();
          data.price[this.editPriceIndex] = this.editPrice;
          await this.product.edit(getFirestore(), data);
          this.editPriceIndex = -1;
        }
        this.getProduct();
        this.editPriceDialog = false;
      }
    },
    deletePrice: async function(item) {
      if(confirm("削除しますか?")) {
        const data = this.product.toJson();
        data.price = data.price.filter((e) => {
          return JSON.stringify(e) !== JSON.stringify(item)
        });
        await this.product.edit(getFirestore(), data);
        this.getProduct();
      }
    },
    createFolder: async function() {
      this.loading = true;
      await this.product.createFolder(getFirestore(), getFunctions());
      this.getProduct();
    },
    confirmSelectedPrices: function() {
      if (this.selectedPrices.length > 0) {
        this.quotationDialog = true;
      } else {
        alert('価格が選択されていません');        
      }
    },
    clickPriceCheckbox: function(item) {
      if (this.selectedPrices.includes(item)) {
        console.log('delete');
        const index = this.selectedPrices.indexOf(item);
        if (index != -1) {
          this.selectedPrices.splice(index, 1);
        }
      } else {
        console.log('push');
        this.selectedPrices.push(item);
      }
    },
    createQuotation: function() {
      const createQuotation = httpsCallable(getFunctions(), "createQuotation");
      const data = {
        customerName: this.customerName,
        product: this.product,
        selectedPrices: this.selectedPrices
      };
      data.selectedPrices.forEach((price) => {
        price.supplierName = (this.allAccount.length > 0) ? this.allAccount.find(account => account.id ==  price.supplierId).name : '';
      });
      console.log(data);
      createQuotation(data);
      this.selectedPrices = [];
      this.quotationDialog = false;
    }
  },
  computed: {
    customerName: function() {
      return (this.allAccount.length > 0) ? this.allAccount.find(account => account.id ==  this.product.customerId).name : ''
    },
  },
  created() {
    this.editProduct = Product.getInitJson();
    this.editPrice = Product.getInitPriceJson();
    this.getProduct();
    this.getAllAccount();
  },
};
</script>
