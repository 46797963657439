var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('h1',{staticClass:"mt-5 ms-5"},[_vm._v("ダッシュボード")]),_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"7"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"5","height":"200"}},[_c('v-container',{staticStyle:{"height":"100%"}},[_c('v-row',{staticStyle:{"height":"100%"},attrs:{"justify":"center","align-content":"center"}},[_c('v-col',{staticClass:"text-center text-h5 font-weight-black",attrs:{"cols":"12"}},[_vm._v(" こんにちは ")]),_c('v-col',{staticClass:"text-center text-h5 font-weight-black",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.user.displayName)+"さん ")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{attrs:{"elevation":"5","height":"400"}},[_c('v-card-title',[_vm._v("直近の商談")]),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","headers":[
                  { text: 'ID', value: 'id' },
                  { text: '商談名', value: 'name' },
                  { text: '取引先', value: 'customer.name' } ],"items":_vm.latestOpp},on:{"click:row":function (item) { return _vm.$router.push('/opp/' + item.id); }}})],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{attrs:{"elevation":"5","height":"400"}},[_c('v-card-title',[_vm._v("利益")]),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","headers":[
                  { text: '月', value: 'month' },
                  { text: '受注ベース', value: 'salesByOrder' },
                  { text: '支払いベース', value: 'salesByPayment' } ],"items":_vm.profit},scopedSlots:_vm._u([{key:"item.salesByOrder",fn:function(ref){
                var item = ref.item;
return [_vm._v(" ￥"+_vm._s(item.salesByOrder.toLocaleString())+" ")]}},{key:"item.salesByPayment",fn:function(ref){
                var item = ref.item;
return [_vm._v(" ￥"+_vm._s(item.salesByPayment.toLocaleString())+" ")]}}],null,true)})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-card',{attrs:{"elevation":"5","height":"600"}},[_c('v-card-title',[_vm._v("月間売上")]),_c('v-card-text',[_c('monthly-sales',{attrs:{"height":230}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }