<template>
  <v-card height="600" elevation="5" @dragover.prevent="overlay=!overlay">
    <v-card-title>
      <template v-for="item in path">
        <a class="subtitle-2 ms-1 me-1" :key="item.id" @click="clickItem(item)">{{ item.name }}</a> / 
      </template>
      <v-spacer />
      <v-card-actions>
        <v-btn color="blue" dark @click="openDrive">Drive</v-btn>
        <v-btn color="blue" dark @click="openFinder">Finder</v-btn>
      </v-card-actions>
    </v-card-title>
    <v-card-text>
      <v-card class="overflow-y-auto" height="400" flat @dragenter="overlay=true" @dragover="overlay=!overlay" @drop.prevent="overlay=false">
        <v-overlay absolute :value="overlay" />
        <v-container v-if="loading">
          <v-row class="mt-10" justify="center">
            <v-progress-circular color="primary" indeterminate v-if="loading" />
          </v-row>
        </v-container>
        <v-container v-else-if="!exists">
          <v-row class="mt-10" justify="center">
            <v-btn color="error" @click="createFolder">フォルダ作成</v-btn>
          </v-row>
        </v-container>
        <v-list v-else two-line>
          <v-divider />
          <v-list-item-group color="primary">
            <template v-for="item in contents">
              <v-list-item @click="clickItem(item)" :key="`second-${item.id}`">
                <v-list-item-avatar>
                  <v-icon :color="item.iconColor" v-text="item.icon" />
                </v-list-item-avatar>
                <v-list-item-title v-text="item.name" />
                <v-list-item-action>
                  <v-btn v-if="item.url != undefined" icon @click.stop="openFile(item)">
                    <v-icon>mdi-arrow-right-circle</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="`first-${item.id}`" />
            </template>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { getFunctions, httpsCallable } from 'firebase/functions';

export default {
  name: 'Drive',
  props: ['idProps'],
  data: () => ({
    overlay: false,
    loading: false,
    exists: false,
    id: '',
    path: [],
    contents: [],
  }),
  methods: {
    debug: function(item) {
      console.log(item);
    },
    clickItem: function(item) {
      console.log(item);
      if(item.mimeType == "application/vnd.google-apps.folder") {
        this.id = item.id;
        this.path = [];
        this.getFiles();
        this.getPath();
      }
    },
    getFiles: async function() {
      this.loading = true;
      const getFiles = httpsCallable(getFunctions(), "getFiles");
      try {
        const res = await getFiles({
          "id": this.id,
        });
        if(res.data) {
          this.contents = res.data;
          this.exists = true;
        }
      } finally {
        this.loading = false;
      }
      this.contents.forEach((item) => {
        switch(item.mimeType) {
          case 'application/vnd.google-apps.folder':
            item.icon = 'mdi-folder';
            break;
          case 'application/pdf':
            item.icon = 'mdi-file-pdf-box';
            item.iconColor = 'red';
            item.url = "https://drive.google.com/file/d/" + item.id;
            break;
          case 'application/vnd.google-apps.spreadsheet':
            item.icon = 'mdi-google-spreadsheet';
            item.iconColor = 'green';
            item.url = 'https://docs.google.com/spreadsheets/d/'+item.id;
            break;
          default:
            item.icon = 'mdi-file';
            break;
        }
      });
    },
    openFile: function(item) {
      window.open(item.url);
    },
    getPath: async function() {
      const getPath = httpsCallable(getFunctions(), "getPath");
      const res = await getPath({
        "id": this.id,
      });
      this.path = res.data;
    },
    openFinder: function() {
      let path = '';
      if(this.path.length > 1) {
        for(let i=1; i<this.path.length; i++) path += '/' + this.path[i].name;
      }
      location.href = 'shortcuts://run-shortcut?name=openFinder&input=/Volumes/GoogleDrive/共有ドライブ/ofpri-app'+path;
    },
    openDrive: function() {
      window.open('https://drive.google.com/drive/u/0/folders/' + this.path[this.path.length-1].id);
    },
    createFolder: function() {
      this.$emit('createFolder');
    }
  },
  computed: {
  },
  created() {
    this.id = this.idProps;
    this.getFiles();
    this.getPath();
  },
};
</script>
