<template>
  <v-app>
    <side-menu />
    <v-main class="blue-grey lighten-5">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import SideMenu from './components/SideMenu.vue';

export default {
  name: 'App',
  components: { SideMenu },
  data: () => ({
  }),
};
</script>
