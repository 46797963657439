import * as firestore from "firebase/firestore"
import {httpsCallable} from "firebase/functions"
import Account from './account';
import Invoice from "./invoice";
import OppProduct from './oppProduct';

class Opp {
  constructor(docId, data) {
    this.docId = docId;
    this.id = data.id;
    this.phase = data.phase;
    this.name = data.name;
    this.customerId = data.customerId;
    this.customer = null;
    this.product = null;
    this.sendingName = data.sendingName;
    this.tel = data.tel;
    this.staff = data.staff;
    this.spec = data.spec;
    this.postel = data.postel;
    this.address1 = data.address1;
    this.address2 = data.address2;
    this.arrangementDate = data.arrangementDate;
    this.deliveryExpectedDate = data.deliveryExpectedDate;
    this.salesExpectedMonth = data.salesExpectedMonth;
    this.deliveryDate = data.deliveryDate;
    this.billingDate = data.billingDate;
    this.paymentDate = data.paymentDate;
    this.folderId = data.folderId;
  }

  static async create(db, data) {
    const ref = firestore.collection(db, "opp");
    const q = firestore.query(ref, firestore.where("id", "==", data.id));
    const snapshot = await firestore.getDocs(q);
    if(snapshot.size > 0) return true;
    await firestore.addDoc(ref, data);
  }

  static async get(db, id) {
    let ret = null;
    const ref = firestore.collection(db, "opp");
    const q = firestore.query(ref, firestore.where("id", "==", id), firestore.limit(1));
    const snapshot = await firestore.getDocs(q);
    snapshot.forEach((e) => {
      ret = new this(e.id, e.data());
    });
    if(ret) {
      ret.customer = await Account.get(db, ret.customerId);
      ret.product = await OppProduct.get(db, ret.id);
    }
    return ret;
  }

  static async getAll(db) {
    const ret = new Array();
    const ref = firestore.collection(db, "opp");
    const q = firestore.query(ref, firestore.orderBy("id", "desc"));
    const snapshot = await firestore.getDocs(q);
    const allProduct = await OppProduct.getAll(db);
    snapshot.forEach((e) => {
      const data = new this(e.id, e.data());
      data.product = allProduct.filter(product => product.oppId === data.id);
      ret.push(data);
    });
    return ret;
  }

  static async getLatest(db) {
    const ret = new Array();
    const ref = firestore.collection(db, "opp");
    const q = firestore.query(ref, firestore.orderBy("id", "desc"), firestore.limit(5));
    const snapshot = await firestore.getDocs(q);
    snapshot.forEach((e) => {
      ret.push(new this(e.id, e.data()));
    });
    for(let i=0; i<ret.length; i++) {
      ret[i].customer = await Account.get(db, ret[i].customerId);
    }
    return ret;
  }

  async delivery(db, func, delivery) {
    const ref = firestore.doc(db, "opp", this.docId);
    const customer = await Account.get(db, this.customerId);

    function calcBillDate(delivery, rule) {
      let year = Number(delivery.split("-")[0]);
      let month = Number(delivery.split("-")[1]);
      let date = 0;
      const deliveryDate = Number(delivery.split('-')[2]);
      // 日付の設定
      if(rule.date != "末") date = Number(rule.date);
      // 月の設定
      // if(rule.date >= deliveryDate && rule.month == "当月") month -= 1;
      // else if((rule.date == "末" && rule.month == "翌月") || (rule.date != "末" && rule.date < deliveryDate && rule.month == "翌々月") || (rule.date != "末" && rule.date >= deliveryDate && rule.month == "翌々月")) month += 1;
      // else if(rule.date == "末" && rule.month == "翌々月") month += 2;
      // else if(rule.data == "" || rule.month == "") return "";
      
      if(rule.date == "" || rule.month == "") return "";
      else if(rule.date == "末") {
        if(rule.month == "当月") {
          month += 0;
        } else if(rule.month == "翌月") {
          month += 1;
        } else {
          month += 2;
        }
      } else if(rule.date < deliveryDate) {
        if(rule.month == "当月" || rule.month == "翌月") {
          month += 0;
        } else {
          month += 1;
        }
      } else {
        if(rule.month == "当月") {
          month -= 1;
        } else if(rule.month == "翌月") {
          month += 0;
        } else {
          month += 1;
        }
      }

      const ret = new Date(year, month, date).getFullYear()+"-"+(new Date(year, month, date).getMonth()+1).toString().padStart(2, "0")+"-"+(new Date(year, month, date).getDate()).toString().padStart(2, "0"); 
      return ret;
    }

    function calcPaymentDate(bill, rule) {
      let year = Number(bill.split("-")[0]);
      let month = Number(bill.split("-")[1])-1;
      let date = 0;
      if(rule.date == "末") {
        month += 1;
        date = 0;
      } else {
        date = Number(rule.date);
      }
      if(rule.month == "翌々月") {
        month += 2;
      } else if(rule.month == "翌月") {
        month += 1;
      }
      const ret = new Date(year, month, date).getFullYear()+"-"+(new Date(year, month, date).getMonth()+1).toString().padStart(2, "0")+"-"+(new Date(year, month, date).getDate()).toString().padStart(2, "0"); 
      return ret;
    }

    if(delivery != undefined && delivery != "") {
      const bill = calcBillDate(delivery, customer.dateRule.bill);
      const payment = calcPaymentDate(bill, customer.dateRule.payment);
      await firestore.updateDoc(ref, {
        "phase.delivery": true,
        "deliveryDate": delivery,
        "billingDate": bill,
        "paymentDate": payment,
      });
      if(bill != "" && payment != "") Invoice.create(db, func, this.id, this.customerId, bill);
    } else {
      await firestore.updateDoc(ref, {
        "phase.delivery": false,
        "phase.billing": false,
        "phase.deposit": false,
        "phase.payment": false,
        "deliveryDate": "",
        "billingDate": "",
        "paymentDate": "",
      });
      this.product.forEach((product) => {
        product.update(db, {
          "supplyCheck": "",
        });
      });
      Invoice.remove(db, this.id);
    }
  }

  async changeBill(db, func, bill) {
    await Invoice.remove(db, this.id);
    const ref = firestore.doc(db, "opp", this.docId);
    await firestore.updateDoc(ref, {
      "billingDate": bill,
    });
    if(bill != "" && this.paymentDate != "") {
      Invoice.create(db, func, this.id, this.customerId, bill);
    } 
  }

  async changePayment(db, func, payment) {
    await Invoice.remove(db, this.id);
    const ref = firestore.doc(db, "opp", this.docId);
    await firestore.updateDoc(ref, {
      "paymentDate": payment,
    });
    if(payment != "" && this.billingDate != "") {
      Invoice.create(db, func, this.id, this.customerId, this.billingDate);
    } 
  }

  async update(db, data) {
    const ref = firestore.doc(db, "opp", this.docId);
    await firestore.updateDoc(ref, data);
  }

  async edit(db, data) {
    const ref = firestore.doc(db, "opp", this.docId);
    if(data.arrangementDate != "") data.phase.arrange = true;
    else data.phase.arrange = false;
    await firestore.setDoc(ref, data);
  }

  async delete(db) {
    const ref = firestore.doc(db, "opp", this.docId);
    this.product.forEach((product) => {
      product.delete(db);
    });
    Invoice.remove(db, this.id);
    await firestore.deleteDoc(ref);
  }

  async createFolder(db, func) {
    const createFolder = httpsCallable(func, "createFolder");
    const res = await createFolder({
      name: this.id + "_" + this.customer.name + "様_" + this.name,
      parents: "1MxRBGfk295RKLyPpKSVgnb144KQev8sR",
    });
    const docRef = firestore.doc(db, "opp", this.docId);
    await firestore.updateDoc(docRef, {
      "folderId": res.data.id,
    });
  }

  async updateFolder(func, data) {
    const updateFolder = httpsCallable(func, "updateFolder");
    await updateFolder({
      id: this.folderId,
      name: this.id + "_" + this.customer.name + "様_" + data.name,
    })
  }

  toJson() {
    const ret = JSON.parse(JSON.stringify(this));
    delete ret['docId'];
    delete ret['product'];
    delete ret['customer'];
    return ret;
  }

  static async getLatestId(db) {
    let id = "";
    const ref = firestore.collection(db, "opp");
    const q = firestore.query(ref, firestore.orderBy("id", "desc"), firestore.limit(1));
    const snapshot = await firestore.getDocs(q);
    snapshot.forEach((e) => {
      id = e.data().id;
    });
    return id;
  }

  static getInitJson() {
    return {
      id: "",
      phase: {
        arrange: false,
        delivery: false,
        billing: false,
        deposit: false,
        payment: false,
      },
      name: "",
      customerId: 0,
      arrangementDate: "",
      deliveryExpectedDate: "",
      salesExpectedMonth: "",
      deliveryDate: "",
      billingDate: "",
      paymentDate: "",
      tel: "",
      fax: "",
      postel: "",
      address1: "",
      address2: "",
      folderId: ""
    }
  }

}

export default Opp;