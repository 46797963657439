<template>
  <v-container fluid style="height: 100%">

    <v-row v-if="loading" style="height: 100%" justify="center" align-content="center">
      <v-col>
        <v-row justify="center">
          <v-progress-circular color="grey darken-2" size="80" width="6" indeterminate />
        </v-row>
      </v-col>
    </v-row>

    <template v-else>
      <h1 class="mt-5 ms-5">商談詳細</h1>
      <v-row class="mt-5">
        <v-col cols="12">
          <v-card elevation="5">
            <v-card-text>
              <v-row justify="center">
                <v-col cols="2">
                  <v-card elevation="5" :color="phase.arrange ? 'green' : 'red'">
                    <v-card-title>
                      <v-checkbox readonly v-model="phase.arrange" dark/><div class="white--text">手配</div>
                    </v-card-title>
                  </v-card>
                </v-col>
                <v-col cols="2">
                  <v-card elevation="5" :color="phase.delivery ? 'green' : 'red'">
                    <v-card-title>
                      <v-checkbox readonly v-model="phase.delivery" dark /><div class="white--text">納品</div>
                    </v-card-title>
                  </v-card>
                </v-col>
                <v-col cols="2">
                  <v-card elevation="5" :color="phase.billing ? 'green' : 'red'">
                    <v-card-title>
                      <v-checkbox readonly v-model="phase.billing" dark /><div class="white--text">請求</div>
                    </v-card-title>
                  </v-card>
                </v-col>
                <v-col cols="2">
                  <v-card elevation="5" :color="(phase.deposit && phase.payment) ? 'green' : 'red'">
                    <v-card-title>
                      <v-checkbox readonly v-model="phase.deposit" dark /><div class="white--text">入金</div>
                      <v-checkbox readonly v-model="phase.payment" dark class="ms-5" /><div class="white--text">支払</div>
                    </v-card-title>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="5">
          <v-card elevation="5" height="850">
            <v-card-actions>
              <v-card-title>基本情報</v-card-title>
              <v-spacer />
              <v-btn v-if="!edit" class="me-8" color="primary" @click="edit=true">編集</v-btn>
              <template v-else>
                <v-btn color="error" @click="cancelEditOpp">中止</v-btn>
                <v-btn class="me-8" color="primary" @click="saveEditOpp">保存</v-btn>
              </template>
            </v-card-actions>
            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <v-text-field outlined readonly v-model="editOpp.id" label="注文番号" />
                </v-col>
                <v-col cols="8">
                  <v-text-field outlined readonly v-model="customerName" label="取引先" append-icon="mdi-arrow-right-circle" @click:append="$router.push('/account/'+opp.customer.id)" />
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined :readonly="!edit" :filled="edit" v-model="editOpp.name" label="商談名" />
                </v-col>
                <v-col cols="12">
                  <v-card-actions>
                    <v-spacer />
                    <v-btn color="success" @click="selectSpecDialog=true">仕様を選択</v-btn>
                  </v-card-actions>
                </v-col>
                <v-col cols="12">
                  <v-textarea outlined :readonly="!edit" :filled="edit" v-model="editOpp.spec" label="商品仕様"></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card elevation="5" height="850">
            <v-card-title>日程管理</v-card-title>
            <v-card-text>
              <v-row>
                <!-- 手配日 -->
                <v-col cols="12">
                  <v-menu
                    ref="arrangementDateDialog"
                    v-model="arrangementDateDialog"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        readonly
                        clearable
                        @click:clear="clearArrangementDate"
                        v-model="editOpp.arrangementDate"
                        label="手配日"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="primary"
                      locale="jp-ja"
                      :day-format="date => new Date(date).getDate()"
                      v-model="editOpp.arrangementDate"
                      no-title
                      @input="saveArrangementDate"
                    ></v-date-picker>
                  </v-menu>                    
                </v-col>
                <!-- 納品予定日 -->
                <v-col cols="12">
                  <v-menu
                    ref="deliveryExpectedDateDialog"
                    v-model="deliveryExpectedDateDialog"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        readonly
                        clearable
                        @click:clear="clearDeliveryExpectedDate"
                        v-model="editOpp.deliveryExpectedDate"
                        label="納品予定日"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="primary"
                      locale="jp-ja"
                      :day-format="date => new Date(date).getDate()"
                      v-model="editOpp.deliveryExpectedDate"
                      no-title
                      @input="saveDeliveryExpected"
                    ></v-date-picker>
                  </v-menu>                    
                </v-col>
                <!-- 売上予定月 -->
                <v-col cols="12">
                  <v-menu
                    ref="salesExpectedMonthDialog"
                    v-model="salesExpectedMonthDialog"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        readonly
                        clearable
                        v-model="editOpp.salesExpectedMonth"
                        label="売上予定月"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="primary"
                      locale="jp-ja"
                      :day-format="date => new Date(date).getDate()"
                      v-model="editOpp.salesExpectedMonth"
                      type="month"
                      no-title
                      @input="saveSalesExpected"
                    ></v-date-picker>
                  </v-menu>                    
                </v-col>
                <v-col cols="12">
                  <v-btn v-if="editOpp.deliveryDate==''" block color="success" @click="complateDelivery">納品完了</v-btn>
                  <v-btn v-else block color="error" @click="cancelDelivery">納品取り消し</v-btn>
                </v-col>
                <!-- 納品日 -->
                <v-col cols="12">
                  <v-menu
                    ref="deliveryDateDialog"
                    v-model="deliveryDateDialog"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        readonly
                        :error="deliveryCheck"
                        v-model="editOpp.deliveryDate"
                        label="納品日"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="primary"
                      locale="jp-ja"
                      :day-format="date => new Date(date).getDate()"
                      v-model="editOpp.deliveryDate"
                      no-title
                      @input="changeDelivery"
                    ></v-date-picker>
                  </v-menu>                    
                </v-col>
                <!-- 請求予定日 -->
                <v-col cols="12">
                  <v-menu
                    ref="billingExpectedDateDialog"
                    v-model="billingExpectedDateDialog"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        readonly
                        v-model="editOpp.billingDate"
                        label="請求予定日"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="primary"
                      locale="jp-ja"
                      :day-format="date => new Date(date).getDate()"
                      v-model="editOpp.billingDate"
                      no-title
                      @input="saveBillingExpected"
                    ></v-date-picker>
                  </v-menu>                    
                </v-col>
                <!-- 入金予定日 -->
                <v-col cols="12">
                  <v-menu
                    ref="paymentExpectedDateDialog"
                    v-model="paymentExpectedDateDialog"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        readonly
                        v-model="editOpp.paymentDate"
                        label="入金予定日"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="primary"
                      locale="jp-ja"
                      :day-format="date => new Date(date).getDate()"
                      v-model="editOpp.paymentDate"
                      no-title
                      @input="savePaymentExpected"
                    ></v-date-picker>
                  </v-menu>                    
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card elevation="5" height="850">
            <v-tabs
              v-model="tab"
              background-color="primary"
              dark
              fixed-tabs
            >
              <v-tab v-text="'送り先'" />
              <v-tab v-text="'Googleドライブ'" />
              <v-tab v-text="'注文書'" />
            </v-tabs>
            <v-card-text>
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <v-row justify="center">
                    <v-col>
                      <v-btn block color="success" @click="startSelectSending">送り先選択</v-btn>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field outlined v-model="editOpp.sendingName" label="送り先名" />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field outlined v-model="editOpp.staff" label="担当者" />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field outlined v-model="editOpp.tel" label="TEL" />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field outlined v-model="editOpp.postel" label="郵便番号" />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field outlined v-model="editOpp.address1" label="住所1" />
                    </v-col>
                    <v-col cols="12">
                      <v-textarea height="100" outlined v-model="editOpp.address2" label="住所2" />
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item>
                  <drive :idProps="editOpp.folderId" @createFolder="createFolder" />
                </v-tab-item>
                <v-tab-item>
                  <v-data-table></v-data-table>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card elevation="5">
            <v-card-title>
              商談商品
              <v-spacer />
              <v-btn class="me-5" color="primary" @click="startCreateOppProduct">新規</v-btn>
            </v-card-title>
            <v-card-text>
              <v-data-table
                hide-default-footer
                :items-per-page="-1"
                :headers="tableHeaders"
                :items="(opp) ? opp.product : []"
              >
                <!-- 数量 -->
                <template v-slot:[`item.quantity`]="{ item }">
                  {{ item.quantity }}{{ item.unit }}
                </template>
                <!-- 販売金額 -->
                <template v-slot:[`item.salesPrice`]="{ item }">
                  ￥{{ (item.quantity * item.unitPrice).toLocaleString() }}
                </template>
                <!-- 税率 -->
                <template v-slot:[`item.tax`]="{ item }">
                  {{ item.tax }}%
                </template>
                <!-- 仕入金額 -->
                <template v-slot:[`item.supplyPrice`]="{ item }">
                  ￥{{ (item.supplyPrice).toLocaleString() }}
                </template>
                <!-- 利益 -->
                <template v-slot:[`item.profitPrice`]="{ item }">
                  ￥{{ (item.quantity * item.unitPrice - item.supplyPrice).toLocaleString() }}
                </template>
                <!-- 利益率 -->
                <template v-slot:[`item.profitRate`]="{ item }">
                  {{ Math.floor((item.quantity * item.unitPrice - item.supplyPrice) / (item.quantity * item.unitPrice) * 10000) / 100 }}%
                </template>
                <!-- 注文書 -->
                <template v-slot:[`item.orderSheet`]="{ item }">
                  <v-btn small color="success" @click="startCreateOrdersheet(item)">作成</v-btn>
                </template>
                <!-- 操作 -->
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small @click.stop="startEditOppProduct(item)" v-text="'mdi-pencil'" />
                  <v-icon small @click.stop="deleteOppProduct(item)" v-text="'mdi-delete'" />
                </template>
                <!-- 合計 -->
                <template v-slot:[`body.append`]>
                  <tr>
                    <td class="font-weight-black">
                      合計
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="font-weight-black">
                      ￥{{ opp.product.reduce((sum, item) => sum + item.quantity * item.unitPrice, 0).toLocaleString() }}
                    </td>
                    <td></td>
                    <td class="font-weight-black">
                      ￥{{ opp.product.reduce((sum, item) => sum + item.supplyPrice, 0).toLocaleString() }}
                    </td>
                    <td class="font-weight-black">
                      ￥{{ opp.product.reduce((sum, item) => sum + item.quantity * item.unitPrice - item.supplyPrice, 0).toLocaleString() }}
                    </td>
                    <td class="font-weight-black">
                      {{ Math.floor(opp.product.reduce((sum, item) => sum + item.quantity * item.unitPrice - item.supplyPrice, 0) / opp.product.reduce((sum, item) => sum + item.quantity * item.unitPrice, 0) * 10000) / 100 }}%
                    </td>
                    <td></td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <v-dialog v-model="editOppProductDialog" max-width="800">
      <v-card elevation="5">
        <v-card-title>
          商品
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="mt-3" justify="center">
              <v-col cols="6">
                <v-select v-model="editOppProduct.templateId" outlined filled clearable @change="selectTemplate" :items="(account) ? account.product : []" item-text="name" item-value="id" label="テンプレート" />
              </v-col>
              <v-col cols="6">
                <v-select outlined filled clearable @change="selectPrice" :items="editPrice" label="価格表">
                  <template v-slot:[`item`]="{ item }">
                    数量: {{ item.quantity }} / 単価: {{ item.unitPrice }} / 仕入: {{ item.supplyPrice }}
                  </template>                  
                  <template v-slot:[`selection`]="{ item }">
                    {{ item.unitPrice }}
                  </template>                  
                </v-select>
              </v-col>
              <v-col cols="7">
                <v-text-field outlined filled v-model="editOppProduct.name" label="商品名" />
              </v-col>
              <v-col cols="5">
                <v-text-field outlined filled readonly v-model="supplierName" label="仕入先" @click="selectSupplierDialog=true" />
                <!-- <v-select outlined filled v-model="editOppProduct.supplierId" :items="allAccount.filter((account) => account.kind.supplier)" item-text="name" item-value="id" label="仕入先" /> -->
              </v-col>
              <v-col cols="4">
                <v-text-field outlined filled type="number" v-model.number="editOppProduct.quantity" label="数量" />
              </v-col>
              <v-col cols="6">
                <v-text-field outlined filled type="number" v-model.number="editOppProduct.unitPrice" label="単価" prefix="@" />
              </v-col>
              <v-col cols="2">
                <v-text-field outlined filled v-model="editOppProduct.unit" label="単位" />
              </v-col>
              <v-col cols="6">
                <v-text-field outlined filled type="number" v-model.number="editOppProduct.tax" label="税率" suffix="%" />
              </v-col>
              <v-col cols="6">
                <v-text-field outlined filled type="number" v-model.number="editOppProduct.supplyPrice" label="仕入れ価格" prefix="¥" />
              </v-col>
              <v-col cols="12">
                <v-textarea outlined filled v-model="editOppProduct.specification" label="仕様" />
              </v-col>
              <v-col class="mt-8" cols="8">
                <v-btn @click="saveEditOppProduct" class="success" block>保存</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="selectSupplierDialog" max-width="1000">
      <v-card>
        <v-card-title>
          <v-text-field append-icon="mdi-magnify" label="検索" v-model="supplierSearch" />
        </v-card-title>
        <v-card-text>
          <v-data-table
            height="400"
            @click:row="selectSupplier"
            :search="supplierSearch"
            :items="allAccount.filter((e) => e.kind.supplier == true)"
            :headers="supplierTableHeaders"
            :items-per-page="-1"
            hide-default-footer
          ></v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    
    <v-dialog v-model="selectSendingDialog" max-width="800">
      <v-card elevation="5">
        <v-card-title>
          送り先選択
        </v-card-title>
        <v-card-text>
          <v-data-table
            hide-default-footer
            :items-per-page="-1"
            @click:row="selectSending"
            :headers="sendingTableHeaders"
            :items="(account) ? account.sending : []"
          >
            <template v-slot:[`item.address`]="{ item }">
              {{ item.address1+item.address2 }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="selectSpecDialog" max-width="800">
      <v-card elevation="5">
        <v-card-title>
          仕様選択
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="[
              { text: '商品名', value: 'name' },
              { text: '仕様', value: 'specification'},
            ]"
            :items="(opp) ? opp.product : []"
            @click:row="selectSpec"
          ></v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="createOrdersheetDialog" max-width="800">
      <v-card height="800">
        <v-card elevation="5">
          <v-card-title>
            注文書作成
          </v-card-title>
          <v-card-text>
            <v-card-subtitle>商品情報</v-card-subtitle>
            <v-container>
              <v-row>
                <v-col cols="4">
                  <v-text-field v-model="ordersheet.oppId" label="注文番号" outlined filled />
                </v-col>
                <v-col cols="8">
                  <v-text-field v-model="ordersheet.productName" label="品名" outlined filled />
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="ordersheet.customer" label="取引先" outlined filled />
                </v-col>
                <v-col cols="8">
                  <v-text-field v-model="ordersheet.supplier" label="仕入先" outlined filled />
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="ordersheet.staff" label="担当者" outlined filled />
                </v-col>
                <v-col cols="6">
                  <v-select :items="['新規', '変更なし', '変更あり']" v-model="ordersheet.type" label="タイプ" outlined filled />
                </v-col>
                <v-col cols="6">
                  <v-select :items="['なし', '添付', '前回内容PDF', 'リンク']" v-model="ordersheet.submitType" label="入稿方法" outlined filled />
                </v-col>
                <v-col cols="8">
                  <v-text-field type="number" v-model.number="ordersheet.quantity" label="数量" outlined filled />
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="ordersheet.unit" label="単位" outlined filled />
                </v-col>
                <v-col cols="6">
                  <v-text-field type="number" prefix="￥" v-model.number="ordersheet.price" label="基本金額" outlined filled />
                </v-col>
                <v-col cols="6">
                  <v-text-field type="number" prefix="￥" v-model.number="ordersheet.optionPrice" label="追加金額" outlined filled />
                </v-col>
                <v-col cols="6">
                  <v-menu
                    ref="ordersheetDeliveryDateDialog"
                    v-model="ordersheetDeliveryDateDialog"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        filled
                        readonly
                        v-model="ordersheet.deliveryDate"
                        clearable
                        label="納品日"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="primary"
                      locale="jp-ja"
                      :day-format="date => new Date(date).getDate()"
                      v-model="ordersheet.deliveryDate"
                      no-title
                    ></v-date-picker>
                  </v-menu>                    
                </v-col>
                <v-col cols="6">
                  <v-menu
                    ref="ordersheetPaymentDateDialog"
                    v-model="ordersheetPaymentDateDialog"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        filled
                        readonly
                        clearable
                        v-model="ordersheet.paymentDate"
                        label="納品日"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="primary"
                      locale="jp-ja"
                      :day-format="date => new Date(date).getDate()"
                      v-model="ordersheet.paymentDate"
                      no-title
                    ></v-date-picker>
                  </v-menu>                    
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="ordersheet.spec" label="仕様" outlined filled />
                </v-col>
              </v-row>
            </v-container>
            <v-card-subtitle>出荷主情報</v-card-subtitle>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-text-field v-model="ordersheet.fromName" label="出荷主名" outlined filled />
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="ordersheet.fromTel" label="TEL" outlined filled />
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="ordersheet.fromPostel" prefix="〒" label="郵便番号" outlined filled />
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="ordersheet.fromAddress1" label="住所1" outlined filled />
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="ordersheet.fromAddress2" label="住所2" outlined filled />
                </v-col>
              </v-row>
            </v-container>
            <v-card-subtitle>受取先情報</v-card-subtitle>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-text-field v-model="ordersheet.toName" label="受取先名" outlined filled />
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="ordersheet.toStaff" label="受取先名" outlined filled />
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="ordersheet.toTel" label="TEL" outlined filled />
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="ordersheet.toPostel" prefix="〒" label="郵便番号" outlined filled />
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="ordersheet.toAddress1" label="住所1" outlined filled />
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="ordersheet.toAddress2" label="住所2" outlined filled />
                </v-col>
              </v-row>
            </v-container>
            <v-btn block color="success" @click="createOrdersheet">作成</v-btn>
          </v-card-text>
        </v-card>
      </v-card>
    </v-dialog>

  </v-container>
</template>
<script>
import { getFirestore } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions';

import Opp from '../model/opp';
import Account from '../model/account';
import OppProduct from '../model/oppProduct';
import Drive from '../components/Drive.vue';

export default {
  components: { Drive },
  name: 'OppPage',
  data: () => ({
    loading: false,
    deliveryCheck: true,
    creatingOppProduct: false,
    editOppProductDialog: false,
    arrangementDateDialog: false,
    salesExpectedMonthDialog: false,
    deliveryExpectedDateDialog: false,
    deliveryDateDialog: false,
    billingExpectedDateDialog: false,
    paymentExpectedDateDialog: false,
    billingDateDialog: false,
    paymentDateDialog: false,
    selectSupplierDialog: false,
    selectSendingDialog: false,
    selectSpecDialog: false,
    createOrdersheetDialog: false,
    ordersheetDeliveryDateDialog: false,
    ordersheetPaymentDateDialog: false,
    // フェーズのラベル
    supplierSearch: "",
    supplierName: "",
    opp: null,
    allAccount: [],
    account: {},
    edit: false,
    tab: 0,
    editOpp: {},
    editOppProduct: {},
    editPrice: [],
    editProduct: null,
    drive: "",
    ordersheet: {}
  }),
  computed: {
    customerName: function() {
      if(this.opp) return this.opp.customer.name;
      else return '';
    },
    tableHeaders() {
      return [
        { text: '名前', value: 'name' },
        { text: '数量', value: 'quantity' },
        { text: '単価', value: 'unitPrice' },
        { text: '税率', value: 'tax' },
        { text: '販売金額', value: 'salesPrice' },
        { text: '仕入先', value: 'supplier.name' },
        { text: '仕入価格', value: 'supplyPrice' },
        { text: '利益', value: 'profitPrice' },
        { text: '利益率', value: 'profitRate' },
        { text: '操作', value: 'actions' },
        { text: '注文書', value: 'orderSheet' },
      ]
    },
    supplierTableHeaders() {
      return [
        { text: 'ID', value: 'id' },
        { text: '顧客名', value: 'name' },
      ]
    },
    sendingTableHeaders() {
      return [
        { text: "送り先名", value: "name" },
        { text: "郵便番号", value: "postel" },
        { text: "住所", value: "address" },
      ]
    },
    phase() {
      if(this.opp) {
        return this.opp.phase;
      } else {
        return {
          arrange: false,
          delivery: false,
          billing: false,
          deposit: false,
          payment: false,
        };
      }
    }
  },
  methods: {
    getOpp: async function() {
      this.loading = true
      this.opp = await Opp.get(getFirestore(), this.$route.params.id);
      console.log(this.opp);
      if(this.opp) {
        this.editOpp = this.opp.toJson();
        if(this.editOpp.deliveryDate != "") this.deliveryCheck = false;
        this.account = await Account.get(getFirestore(), this.opp.customerId);
        this.account.getProduct(getFirestore());
        this.loading = false;
      } else {
        alert("商談が存在しません");
        this.$router.push('/opp');
      }
    },
    getAllAccount: async function() {
      this.allAccount = await Account.getAll(getFirestore());
    },
    cancelEditOpp: async function() {
      if(confirm("キャンセルしますか?")) {
        await this.getOpp();
        this.edit = false;
      }
    },
    saveEditOpp: async function() {
      if(confirm("保存しますか?")) {
        await this.opp.edit(getFirestore(), this.editOpp);
        if(this.opp.folderId != "") {
          await this.opp.updateFolder(getFunctions(), this.editOpp);
        }
        this.getOpp();
        this.edit = false;
      }
    },
    deleteOppProduct: async function(product) {
      if(confirm(product.name + "を削除しますか?")) {
        await product.delete(getFirestore());
        this.getOpp();
      }
    },
    startCreateOppProduct: function() {
      this.creatingOppProduct = true;
      this.editOppProduct = OppProduct.getInitJson();
      const account = this.allAccount.find(e => e.id == this.editOppProduct.supplierId);
      this.supplierName = (account != undefined) ? account.name : "";
      this.editOppProduct['oppId'] = this.$route.params.id;
      this.editOppProductDialog = true;
    },
    startEditOppProduct: function(product) {
      this.supplierName = product.supplier.name;
      this.creatingOppProduct = false;
      this.editProduct = product;
      this.editOppProduct = product.toJson();
      this.editOppProduct['oppId'] = this.$route.params.id;
      this.editOppProductDialog = true;
    },
    selectTemplate: function(templateId) {
      if(templateId) {
        const template = this.account.product.find(e => e.id === templateId);
        this.editOppProduct = Object.assign({}, this.editOppProduct, template.toJson());
        this.editPrice = this.editOppProduct.price;
        delete this.editOppProduct.customerId;
        delete this.editOppProduct.folderId;
        delete this.editOppProduct.customerId;
        delete this.editOppProduct.price;
        this.editOppProduct['oppId'] = this.$route.params.id;
        this.editOppProduct.templateId = templateId;
      } else {
        this.editOppProduct = OppProduct.getInitJson();
      }
    },
    selectPrice: function(price) {
      console.log(price);
      if(price) {
        this.editOppProduct.unitPrice = price.unitPrice;
        this.editOppProduct.quantity = price.quantity;
        this.editOppProduct.supplierId = price.supplierId;
        const account = this.allAccount.find(e => e.id == price.supplierId);
        this.supplierName = (account != undefined) ? account.name : "";
        this.editOppProduct.supplyPrice = price.supplyPrice;
      } else {
        this.editOppProduct.unitPrice = 0;
        this.editOppProduct.quantity = 0;
        this.editOppProduct.supplierId = 0;
        this.supplierName = "";
        this.editOppProduct.supplyPrice = 0;
      }
    },
    selectSupplier: function(item) {
      this.supplierName = item.name;
      this.editOppProduct.supplierId = item.id;
      this.selectSupplierDialog = false;
    },
    saveEditOppProduct: async function() {
      if(confirm("保存しますか?")) {
        if(this.creatingOppProduct) {
          await OppProduct.create(getFirestore(), this.editOppProduct);
        } else {
          await this.editProduct.edit(getFirestore(), this.editOppProduct);
        }
        this.getOpp();
        this.editOppProductDialog = false;
      }
    },
    complateDelivery: async function() {
      if (this.opp.arrangementDate === '' || this.opp.arrangementDate === undefined) {
        alert('手配日を入力してください');
      } else if(confirm("納品完了にしますか?")) {
        const now = new Date().getFullYear() + "-" + (new Date().getMonth()+1).toString().padStart(2, "0") + "-" + new Date().getDate().toString().padStart(2, "0");
        await this.opp.delivery(getFirestore(), getFunctions(), now);
        this.getOpp();
      }
    },
    changeDelivery: async function() {
      if (this.opp.arrangementDate === '' || this.opp.arrangementDate === undefined) {
        alert('手配日を入力してください');
        this.getOpp();
      } else if(confirm("納品完了にしますか?")) {
        this.deliveryDateDialog=false;
        this.deliveryCheck=false;
        await this.opp.delivery(getFirestore(), getFunctions(), this.editOpp.deliveryDate);
        this.getOpp();
      } else {
        this.getOpp();
      }
    },
    cancelDelivery: async function() {
      if(confirm("納品取り消ししますか?")) {
        await this.opp.delivery(getFirestore(), "");
        this.getOpp();
      }
    },
    saveBillingExpected: async function() {
      if(confirm("請求予定日を変更しますか?")) {
        this.billingExpectedDateDialog = false;
        await this.opp.changeBill(getFirestore(), getFunctions(), this.editOpp.billingDate);
        this.getOpp();
      }
    },
    saveSalesExpected: async function() {
      if(confirm("売上予定月を変更しますか?")) {
        this.salesExpectedMonthDialog = false;
        await this.opp.update(getFirestore(), {
          salesExpectedMonth: this.editOpp.salesExpectedMonth
        });
        this.getOpp();
      }
    },
    savePaymentExpected: async function() {
      if(confirm("支払い予定日を変更しますか?")) {
        this.paymentExpectedDateDialog = false;
        await this.opp.changePayment(getFirestore(), getFunctions(), this.editOpp.paymentDate);
        this.getOpp();
      }
    },
    startSelectSending: function() {
      this.selectSendingDialog = true;
    },
    selectSending: async function(item) {
      console.log(item);
      if(confirm("選択しますか?")) {
        this.editOpp.sendingName = item.name;
        this.editOpp.tel = item.tel;
        this.editOpp.staff = item.staff;
        this.editOpp.postel = item.postel;
        this.editOpp.address1 = item.address1;
        this.editOpp.address2 = item.address2;
        await this.opp.edit(getFirestore(), this.editOpp);
        this.getOpp();
        this.selectSendingDialog = false;
      }
    },
    saveArrangementDate: async function() {
      if(confirm("保存しますか?")) {
        this.loading = true;
        this.arrangementDateDialog=false;
        this.phase.arrange = true;
        await this.opp.edit(getFirestore(), this.editOpp);
        this.getOpp();
      }      
    },
    clearArrangementDate: async function() {
      this.loading = true;
      this.arrangementDateDialog=false;
      if(confirm("保存しますか?")) {
        this.phase.arrange = false;
        this.editOpp.arrangementDate = "";
        await this.opp.edit(getFirestore(), this.editOpp);
        this.getOpp();
      }
    },
    saveDeliveryExpected: async function() {
      if(confirm("保存しますか?")) {
        this.loading = true;
        this.deliveryExpectedDateDialog=false;
        await this.opp.edit(getFirestore(), this.editOpp);
        this.getOpp();
      }
    },
    clearDeliveryExpectedDate: async function() {
      if(confirm("保存しますか?")) {
        this.loading = true;
        this.deliveryExpectedDateDialog=false;
        this.editOpp.deliveryExpectedDate = "";
        await this.opp.edit(getFirestore(), this.editOpp);
        this.getOpp();
      }
    },
    createFolder: async function() {
      await this.opp.createFolder(getFirestore(), getFunctions());
      this.getOpp();
    },
    selectSpec: async function(item) {
      await this.opp.update(getFirestore(), {
        "spec": item.specification
      });
      this.selectSpecDialog = false;
      this.getOpp();
    },
    startCreateOrdersheet: function(item) {
      this.ordersheet = {
        "oppId": this.opp.id,
        "productName": item.name,
        "customer": this.customerName,
        "supplier": item.supplier.name,
        "staff": '',
        "submitType": "なし",
        "type": "新規",
        "quantity": item.quantity,
        "unit": item.unit,
        "price": item.supplyPrice,
        "optionPrice": 0,
        "deliveryDate": this.opp.deliveryExpectedDate,
        "paymentDate": '',
        "spec": item.specification,
        "fromName": "株式会社オフィスプリント",
        "fromTel": "048-862-9939",
        "fromPostel": "330-0062",
        "fromAddress1": "埼玉県さいたま市浦和区",
        "fromAddress2": "仲町2-5-1 ロイヤルパインズホテルB1F  Mio浦和",
        "toName": this.opp.sendingName,
        "toStaff": this.opp.staff,
        "toTel": this.opp.tel,
        "toPostel": this.opp.postel,
        "toAddress1": this.opp.address1,
        "toAddress2": this.opp.address2,
      };
      this.createOrdersheetDialog = true;
    },
    createOrdersheet: function() {
      const createOrdersheet = httpsCallable(getFunctions(), "createOrdersheet");
      createOrdersheet(this.ordersheet);
      this.createOrdersheetDialog = false;
    }
  },
  async created() {
    this.editOpp = Opp.getInitJson();
    console.log(this.editOpp);
    this.editOppProduct = OppProduct.getInitJson();
    this.getOpp();
    this.getAllAccount();
  }
}
</script>