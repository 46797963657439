<template>
  <v-container fluid>
    <h1 class="mt-5 ms-5">取引先一覧</h1>
    <v-row class="mt-5">
      <v-col cols="12">
        <v-card elevation="5">
          <v-card-title>
            <v-text-field @change="doSearch" @click:clear="search.text=''" clearable label="テキストを入力" v-model="search.text" />
            <v-switch @change="doSearch" class="ms-5" color="blue" label="BNI" v-model="search.bni" />
            <v-switch @change="doSearch" class="ms-5" color="green" label="取引先" v-model="search.customer" />
            <v-switch @change="doSearch" class="ms-5" color="red" label="仕入先" v-model="search.supplier" />
          </v-card-title>
          <v-card-text>
            <v-data-table
              hide-default-footer
              @click:row="(item) => $router.push('/account/' + item.id)"
              :items-per-page="-1"
              :headers="tableHeaders"
              :items="allAccount"
              :search="$route.query.text"
            >
              <!-- 種別 -->
              <template v-slot:[`item.kind`]="{ item }">
                <v-chip class="mt-1 mb-1 ms-1" small v-if="item.kind.bni" color="blue" dark>BNI</v-chip>
                <v-chip class="mt-1 mb-1 ms-1" small v-if="item.kind.customer" color="green" dark>取引先</v-chip>
                <v-chip class="mt-1 mb-1 ms-1" small v-if="item.kind.supplier" color="red" dark>仕入先</v-chip>
              </template>
              <!-- 請求 -->
              <template v-slot:[`item.bill`]="{ item }">
                {{ item.dateRule.bill.month + item.dateRule.bill.date }}{{ (item.dateRule.bill.date!="")? '日' : '' }}
              </template>
              <!-- 支払い -->
              <template v-slot:[`item.payment`]="{ item }">
                {{ item.dateRule.payment.month + item.dateRule.payment.date }}{{ (item.dateRule.bill.date!="")? '日' : '' }}
              </template>
              <!-- 操作 -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click.stop="deleteAccount(item)" v-text="'mdi-delete'" />
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-btn
      x-large
      fixed
      color="blue"
      dark
      bottom
      right
      fab
      @click="startEditAccount"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <v-dialog v-model="editDialog" max-width="800">
      <v-card elevation="5">
        <v-card-title>新規取引先</v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="mt-3" justify="center">
              <v-col cols="6">
                <v-text-field outlined filled v-model="editAccount.name" label="取引名" />
              </v-col>
              <v-col cols="2">
                <v-switch color="blue" label="BNI" v-model="editAccount.kind.bni" />
              </v-col>
              <v-col cols="2">
                <v-switch color="green" label="取引先" v-model="editAccount.kind.customer" />
              </v-col>
              <v-col cols="2">
                <v-switch color="red" label="仕入先" v-model="editAccount.kind.supplier" />
              </v-col>
              <v-col cols="2">
                <v-select outlined filled label="請求月" :items="['当月', '翌月']" v-model="editAccount.dateRule.bill.month" />
              </v-col>
              <v-col cols="4">
                <v-select outlined filled label="請求日" :items="['月末'].concat(new Array(30).fill(0).map((e, i) => String(30-i)))" v-model="editAccount.dateRule.bill.date" />
              </v-col>
              <v-col cols="2">
                <v-select outlined filled label="支払月" :items="['当月', '翌月']" v-model="editAccount.dateRule.payment.month" />
              </v-col>
              <v-col cols="4">
                <v-select outlined filled label="支払日" :items="['月末'].concat(new Array(30).fill(0).map((e, i) => String(30-i)))" v-model="editAccount.dateRule.payment.date" />
              </v-col>
              <v-col cols="4">
                <v-text-field outlined filled prefix="〒" label="郵便番号"  v-model="editAccount.address.postel" />
              </v-col>
              <v-col cols="8">
                <v-text-field outlined filled label="住所1"  v-model="editAccount.address.address1" />
              </v-col>
              <v-col cols="12">
                <v-textarea outlined filled label="住所2"  v-model="editAccount.address.address2" />
              </v-col>              
              <v-col class="mt-8" cols="8">
                <v-btn @click="createAccount" class="success" block>作成</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import { getFirestore } from 'firebase/firestore'
import Account from '../model/account';

export default {
  name: 'AccountList',
  data: () => ({
    // ダイアログのフラグ
    editDialog: false,
    // 新規作成
    editAccount: {},
    // テーブル検索
    search: {
      text: '',
      bni: false,
      customer: false,
      supplier: false,
    },
    allAccount: [],
  }),
  methods: {
    getAllAccount: async function() {
      this.allAccount = await Account.getAll(getFirestore());
      this.search = {
        bni: this.$route.query.bni,
        customer: this.$route.query.customer,
        supplier: this.$route.query.supplier,
        text: this.$route.query.text,
      };
    },
    startEditAccount: function() {
      this.editAccount = Account.getInitJson();
      this.editDialog = true;
    },
    createAccount: async function() {
      Account.create(getFirestore(), this.editAccount);
      this.getAllAccount();
      this.editDialog = false;
    },
    deleteAccount: function(account) {
      if(confirm(account.name+'を削除しますか?')) {
        account.delete(getFirestore());
        this.getAllAccount();
      }
    },
    doSearch() {
      this.$router.push({
        path: '/account',
        query: {
          text: (this.search.text != '') ? this.search.text : undefined,
          bni: (this.search.bni) ? 'true': undefined,
          customer: (this.search.customer) ? 'true': undefined,
          supplier: (this.search.supplier) ? 'true': undefined
        }
      });
    }
  },
  computed: {
    // 一覧テーブルヘッダー
    tableHeaders() {
      return [
        { text: 'ID', value: 'id' },
        { text: '名前', value: 'name' },
        { text: '種別', value: 'kind', filter: (value) => ((!this.$route.query.bni || value.bni)&&(!this.$route.query.customer || value.customer)&&(!this.$route.query.supplier || value.supplier)) },
        { text: '請求', value: 'bill' },
        { text: '支払い', value: 'payment' },
        { text: '操作', value: 'actions' },
      ]
    }
  },
  created() {
    this.editAccount = Account.getInitJson();
    this.getAllAccount();
  },
};
</script>
