<template>
  <v-container fluid>
    <h1 class="mt-5 ms-5">取引先詳細</h1>
    <v-row class="mt-5">
      <v-col cols="7">
        <v-card elevation="5">
          <v-card-actions>
            <v-card-title>基本情報</v-card-title>
            <v-spacer />
            <v-btn v-if="!edit" class="me-8" color="primary" @click="startEditAccount">編集</v-btn>
            <template v-else>
              <v-btn color="error" @click="cancelEditAccount">中止</v-btn>
              <v-btn class="me-8" color="primary" @click="saveEditAccount">保存</v-btn>
            </template>
          </v-card-actions>
          <v-card-text>
            <v-container>
              <v-row justify="center">
                <v-col cols="3">
                  <v-switch :readonly="!edit" color="blue" label="BNI" v-model="editAccount.kind.bni" />
                </v-col>
                <v-col cols="3">
                  <v-switch :readonly="!edit" color="green" label="取引先" v-model="editAccount.kind.customer" />
                </v-col>
                <v-col cols="3">
                  <v-switch :readonly="!edit" color="red" label="仕入先" v-model="editAccount.kind.supplier" />
                </v-col>
                <v-col cols="3" />
                <v-col cols="4">
                  <v-text-field outlined readonly v-model="editAccount.id" label="ID" />
                </v-col>
                <v-col cols="8">
                  <v-text-field outlined :readonly="!edit" :filled="edit" v-model="editAccount.name" label="取引名" />
                </v-col>
                <v-col cols="2">
                  <v-select outlined :readonly="!edit" :filled="edit" label="請求月" :items="['当月', '翌月', '翌々月']" v-model="editAccount.dateRule.bill.month" />
                </v-col>
                <v-col cols="4">
                  <v-select outlined :readonly="!edit" :filled="edit" label="請求日" :items="['末'].concat(new Array(30).fill(0).map((e, i) => String(30-i)))" v-model="editAccount.dateRule.bill.date" />
                </v-col>
                <v-col cols="2">
                  <v-select outlined :readonly="!edit" :filled="edit" label="支払月" :items="['当月', '翌月', '翌々月']" v-model="editAccount.dateRule.payment.month" />
                </v-col>
                <v-col cols="4">
                  <v-select outlined :readonly="!edit" :filled="edit" label="支払日" :items="['末'].concat(new Array(30).fill(0).map((e, i) => String(30-i)))" v-model="editAccount.dateRule.payment.date" />
                </v-col>
                <v-col cols="4">
                  <v-text-field outlined :readonly="!edit" :filled="edit" prefix="〒" label="郵便番号"  v-model="editAccount.address.postel" />
                </v-col>
                <v-col cols="8">
                  <v-text-field outlined :readonly="!edit" :filled="edit" label="住所1"  v-model="editAccount.address.address1" />
                </v-col>
                <v-col cols="12">
                  <v-textarea outlined :readonly="!edit" :filled="edit" label="住所2"  v-model="editAccount.address.address2" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="5">
        <v-card elevation="5">
          <v-tabs
            v-model="tab"
            background-color="primary"
            dark
            fixed-tabs
          >
            <v-tab v-text="'商品'" />
            <v-tab v-text="'送り先'" />
          </v-tabs>
          <v-card-text>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-card-actions>
                  <v-spacer />
                  <v-btn @click="startCreateProduct" small color="success">作成</v-btn>
                </v-card-actions>
                <v-data-table
                  hide-default-footer
                  :items-per-page="-1"
                  :headers="productTableHeaders"
                  :items="(account) ? account.product : []"
                  @click:row="(item) => $router.push('/product/'+item.customerId+'-'+item.id)"
                >
                  <!-- 操作 -->
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small @click.stop="startEditProduct(item)" v-text="'mdi-pencil'" />
                    <v-icon small @click.stop="deleteProduct(item)" v-text="'mdi-delete'" />
                  </template>
                </v-data-table>
              </v-tab-item>
              <v-tab-item>
                <v-card-actions>
                  <v-spacer />
                  <v-btn small color="success" @click="startCreateSending">作成</v-btn>
                </v-card-actions>
                <v-data-table
                  hide-default-footer
                  :items-per-page="-1"
                  :headers="sendingTableHeaders"
                  :items="(account) ? account.sending: []"
                >
                  <!-- 住所 -->
                  <template v-slot:[`item.address`]="{ item }">
                    {{ item.address1 }}<br>{{ item.address2 }}
                  </template>
                  <!-- 操作 -->
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small @click.stop="startEditSending(item)" v-text="'mdi-pencil'" />
                    <v-icon small @click.stop="deleteSending(item)" v-text="'mdi-delete'" />
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="editProductDialog" max-width="800">
      <v-card elevation="5">
        <v-card-title>商品{{ (createProduct) ? '作成': '編集'}}</v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="mt-3" justify="center">
              <v-col cols="8">
                <v-text-field outlined filled v-model="editProduct.name" label="商品名" />
              </v-col>
              <v-col cols="4">
                <v-text-field outlined filled v-model="editProduct.unit" label="単位" />
              </v-col>
              <v-col cols="12">
                <v-textarea outlined filled v-model="editProduct.specification" label="仕様" />
              </v-col>
              <v-col class="mt-8" cols="8">
                <v-btn @click="saveEditProduct" class="success" block>保存</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editSendingDialog" max-width="800">
      <v-card elevation="5">
        <v-card-title>送り先{{ (createSending) ? '作成': '編集'}}</v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="mt-3" justify="center">
              <v-col cols="12">
                <v-text-field outlined filled v-model="editSending.name" label="名前" />
              </v-col>
              <v-col cols="6">
                <v-text-field outlined filled v-model="editSending.tel" label="TEL" />
              </v-col>
              <v-col cols="6">
                <v-text-field outlined filled v-model="editSending.staff" label="担当者" />
              </v-col>
              <v-col cols="4">
                <v-text-field outlined filled v-model="editSending.postel" label="郵便番号" />
              </v-col>
              <v-col cols="8"></v-col>
              <v-col cols="12">
                <v-text-field outlined filled v-model="editSending.address1" label="住所1" />
              </v-col>
              <v-col cols="12">
                <v-textarea outlined filled v-model="editSending.address2" label="住所2" />
              </v-col>
              <v-col class="mt-8" cols="8">
                <v-btn @click="saveEditSending" class="success" block>保存</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import { getFirestore } from 'firebase/firestore'

import Account from '../model/account';
import Product from '../model/product';
import Address from '../model/address';

export default {
  name: 'AccountPage',
  data: () => ({
    // 編集ダイアログ
    editProductDialog: false,
    editSendingDialog: false,
    createProduct: false,
    createSending: false,
    // 編集のフラグ
    edit: false,
    // タブ
    tab: 0,
    // 編集
    editAccount: {},
    editProduct: {},
    editSending: {},
    // クラス
    account: null,
    product: null,
    sending: null,
  }),
  methods: {
    getAccount: async function() {
      this.account = await Account.get(getFirestore(), Number(this.$route.params.id));
      if(this.account) {
        this.editAccount = this.account.toJson();
        this.account.getProduct(getFirestore());
      } else {
        alert("取引先が存在しません");
        this.$router.push('/account');
      }
    },
    startEditAccount: function() {
      this.edit = true;
    },
    cancelEditAccount: function() {
      if(confirm("編集をキャンセルしますか?")) {
        this.getAccount();
        this.edit = false;
      }
    },
    saveEditAccount: async function() {
      if(confirm("保存しますか?")) {
        await this.account.edit(getFirestore(), this.editAccount);
        this.getAccount();
        this.edit = false;
      }
    },
    startCreateProduct: function() {
      this.createProduct = true;
      this.editProduct = Product.getInitJson();
      this.editProductDialog = true;
    },
    startEditProduct: function(item) {
      this.createProduct = false;
      this.product = item;
      this.editProduct = item.toJson();
      this.editProductDialog = true;
    },
    saveEditProduct: async function() {
      if(confirm(this.editProduct.name+'を保存しますか?')) {
        if(this.createProduct) {
          await this.account.addProduct(getFirestore(), this.editProduct);
        } else {
          await this.product.edit(getFirestore(), this.editProduct);
        }
        this.getAccount();
        this.editProductDialog = false;
      }
    },
    deleteProduct: async function(product) {
      if(confirm(product.name+'を削除しますか?')) {
        await product.delete(getFirestore());
        this.getAccount();
      }
    },
    startCreateSending: function() {
      this.createSending = true;
      this.editSending = Address.getInitJson();
      this.editSending.accountId = this.account.id;
      this.editSendingDialog = true;
    },
    startEditSending: function(item) {
      this.createSending = false;
      this.sending = item;
      this.editSending = item.toJson();
      this.editSending.accountId = this.account.id;
      this.editSendingDialog = true;
    },
    saveEditSending: async function() {
      if(confirm('保存しますか?')) {
        if(this.createSending) {
          await Address.create(getFirestore(), this.editSending);
        } else {
          await this.sending.edit(getFirestore(), this.editSending);
        }
        this.getAccount();
        this.editSendingDialog = false;
      }
    },
    deleteSending: async function(item) {
      if(confirm('削除しますか?')) {
        await item.delete(getFirestore());
        this.getAccount();
      }
    },
  },
  computed: {
    // 商品一覧テーブルヘッダー
    productTableHeaders() {
      return [
        { text: 'ID', value: 'id' },
        { text: '名前', value: 'name' },
        { text: '操作', value: 'actions' },
      ]
    },
    // 送り先一覧テーブルヘッダー
    sendingTableHeaders() {
      return [
        { text: '送り先名', value: 'name' },
        { text: '担当者', value: 'staff' },
        { text: '郵便番号', value: 'postel' },
        { text: '住所', value: 'address' },
        { text: '電話番号', value: 'tel' },
        { text: '操作', value: 'actions' },
      ]
    }

  },
  created() {
    this.editAccount = Account.getInitJson();
    this.editProduct = Product.getInitJson();
    this.getAccount();
  },
};
</script>
