import * as firestore from "firebase/firestore"

class Address {
  constructor(docId, data) {
    this.docId = docId;
    this.accountId = data.accountId;
    this.name = data.name;
    this.tel = data.tel;
    this.staff = data.staff;
    this.postel = data.postel;
    this.address1 = data.address1;
    this.address2 = data.address2;
  }

  static getInitJson() {
    return {
      accountId: 0,
      name: "",
      staff: "",
      tel: "",
      postel: "",
      address1: "",
      address2: "",
    }
  }

  static async create(db, data) {
    const ref = firestore.collection(db, "address");
    await firestore.addDoc(ref, data);
  }

  static async get(db, accountId) {
    const ret = [];
    const ref = firestore.collection(db, "address");
    const q = firestore.query(ref, firestore.where("accountId", "==", accountId));
    const snapshot = await firestore.getDocs(q);
    snapshot.forEach((e) => {
      ret.push(new this(e.id, e.data()));
      console.log(e.data());
    });
    return ret;
  }

  toJson() {
    const ret = JSON.parse(JSON.stringify(this));
    delete ret["docId"];
    return ret;
  }

  async edit(db, data) {
    const ref = firestore.doc(db, "address", this.docId);
    await firestore.setDoc(ref, data);
  }

  async delete(db) {
    const ref = firestore.doc(db, "address", this.docId);
    await firestore.deleteDoc(ref);
  }

}

export default Address;